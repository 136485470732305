import { useEffect } from 'react';
import { Checkbox, useTranslations } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useOrderDataContext } from '@src/contexts/OrderDataContext';
import { useTariffsContext } from '@src/contexts/TariffsContext';
import { Path } from '@src/types/routes';

import Details from './components/Details';
import Total from './components/Total';

export default function DeliveryDetails() {
  const { isRecipientPays, recordWhoPays } = useOrderDataContext();
  const { selectedTariff } = useTariffsContext();
  const { setButtonState, setIsEditCell, setIsEditInsurance } =
    useFlowManagerContext();
  const { t } = useTranslations();

  const isRecipientPaysHandler = () => {
    recordWhoPays(!isRecipientPays);
  };

  useEffect(() => {
    setButtonState({
      text: selectedTariff ? t('next') : t('chooseTariff'),
      link: { to: selectedTariff ? Path.Authorization : Path.AllTariffs },
      isActive: true,
    });
  }, [selectedTariff, setButtonState, t]);

  useEffect(() => {
    setIsEditCell(false);
    setIsEditInsurance(false);
  }, [setIsEditCell, setIsEditInsurance]);

  return (
    <FlowManager title={t('deliveryDetails')}>
      <Total />
      <Details />
      <Checkbox checked={isRecipientPays} onChange={isRecipientPaysHandler}>
        {t('paymentRecipient')}
      </Checkbox>
    </FlowManager>
  );
}

import { useEffect, useState } from 'react';
import { useErrorHandlerContext } from '@omnipkg/ui-kit-web';

import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';

import {
  DeviseConfigResponse,
  useGetDeviceConfigs,
} from '../utils/getDeviceConfigs';

export default function useDeviceConfigs() {
  const [isLoading, setIsLoading] = useState(true);
  const [deviceConfig, setDeviceConfig] = useState<DeviseConfigResponse>();

  const { deviceInfo } = useDeviceInfoContext();
  const { handleError } = useErrorHandlerContext();

  const getDeviceConfigs = useGetDeviceConfigs();

  useEffect(() => {
    if (!deviceInfo) return;

    const { uid } = deviceInfo;

    getDeviceConfigs({ deviceUid: uid })
      .then((data) => {
        setDeviceConfig(data);
      })
      .catch((error) => {
        handleError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [deviceInfo, getDeviceConfigs, handleError]);

  return { isLoading, deviceConfig };
}

import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';

import { OrderData } from '@src/contexts/OrderDataContext/types';

import { CreateOrderRequest, CreatingOrderValues } from './types';

const baseURL = process.env.API_ECOM;

export function useCreateOrder() {
  const { request } = useApiContext();

  return useCallback(
    async (values: CreatingOrderValues): Promise<OrderData> => {
      const { data } = await request<OrderData, CreateOrderRequest>({
        baseURL,
        method: 'POST',
        url: 'type-1/order',
        headers: {
          Authorization: `Bearer ${values.bearerToken}`,
        },
        data: {
          number: values.number,
          cost: {
            declared_cost: values.declaredCost,
            services_payment: [
              {
                sum: values.sum,
              },
            ],
          },
          sender: {
            name: `${values.senderFirstName} ${values.senderLastName}`,
            phone: values.senderPhone,
          },
          receiver: {
            name: `${values.recipientFirstName} ${values.recipientLastName}`,
            phone: values.recipientPhone,
          },
          location_from: {
            point: {
              code: values.from.code,
              brand: values.from.brand,
            },
          },
          location_to: values.to,
          direction_type: 0,
          delivery_options: {
            sending_parcel_locker: true,
          },
          height: values.height,
          width: values.width,
          length: values.length,
          weight: values.weight,
          places: [
            {
              number: 1,
              height: values.height,
              width: values.width,
              length: values.length,
              weight: values.weight,
            },
          ],
          tariff_code: values.tariffCode,
        },
      });

      return data;
    },
    [request],
  );
}

import { useEffect } from 'react';
import { useTranslations } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import Message from '@src/components/Message';
import Success from '@src/components/SVGIcon/icons/Success';
import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { Path } from '@src/types/routes';

export default function PaymentSuccess(): JSX.Element {
  const { deviceInfo } = useDeviceInfoContext();
  const { setIsPayBtn, setButtonState } = useFlowManagerContext();
  const { t } = useTranslations();

  const isHasPrinter = deviceInfo?.device_with_printer;

  useEffect(() => {
    setIsPayBtn(false);

    setButtonState({
      text: isHasPrinter ? t('next') : t('openCell'),
      isActive: true,
      isLoading: false,
      link: { to: isHasPrinter ? Path.PrintLabel : Path.PlaceParcel },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlowManager>
      <Message icon={<Success />} title={t('paymentSuccess')}></Message>
    </FlowManager>
  );
}

import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';

import { Tariff, TariffRequest } from '@src/contexts/TariffsContext/types';

interface GetTariffsData {
  token: string;
  from: {
    countryCode: string;
    region: string;
    city: string;
  };
  to: {
    pointTypes: number;
    countryCode?: string;
    region?: string;
    city?: string;
    citPlaceId?: string;
  };
  places: {
    height: number;
    length: number;
    width: number;
    weight: number;
    insurance: number;
  };
}

const baseURL = process.env.API_TARIFFS;

export default function useGetTariffCalculatorRequest() {
  const { request } = useApiContext();

  return useCallback(
    async (values: GetTariffsData): Promise<Tariff[]> => {
      const { data } = await request<Tariff[], TariffRequest>({
        baseURL: baseURL as string,
        url: '/tariff/calculator',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${values.token}`,
        },
        data: {
          location_from: {
            country_code: values.from.countryCode,
            region: values.from.region,
            city: values.from.city,
          },
          location_to: {
            point_types: [values.to.pointTypes],
            country_code: values.to.countryCode,
            region: values.to.city,
            city: values.to.city,
            city_place_id: values.to.citPlaceId,
          },
          places: [
            {
              height: values.places.height,
              length: values.places.length,
              width: values.places.width,
              weight: values.places.weight * 1000,
            },
          ],
          additional_services: [
            {
              service: 'insurance',
              value: values.places.insurance,
            },
          ],
        },
      });

      return data;
    },
    [request],
  );
}

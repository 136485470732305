import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';

interface CellsStatusResponse {
  device_uid: string;
  cell_types: ExtendedCellsInfo[];
}

interface ExtendedCellsInfo {
  type: string;
  has_empty: boolean;
  params: CellParams;
}

interface CellParams {
  width: number;
  height: number;
  depth: number;
  label: string;
}

interface GetCellStatusesArgs {
  deviceUid: string;
}

export default function useGetCellsStatusesRequest() {
  const { request } = useApiContext();

  return useCallback(
    async ({ deviceUid }: GetCellStatusesArgs) => {
      const { data } = await request<CellsStatusResponse>({
        url: `public/orders/device/${deviceUid}/cells/statuses/`,
        method: 'GET',
      });

      return data;
    },
    [request],
  );
}

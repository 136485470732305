import { useEffect } from 'react';
import { useTranslations } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useOrderDataContext } from '@src/contexts/OrderDataContext';
import { Path } from '@src/types/routes';

import Loading from './components/Loading/Loading';
import { useLabelCreation } from './hooks/useLabelCreation';
import { useLabelLink } from './hooks/useLabelLink';
import PrintLabeImg from './images/print-label-min.jpeg';
import PrintSuccess from './images/print-success-min.jpeg';

import { Image, Title } from './styled';

export default function PrintLabel() {
  const { labelLink } = useOrderDataContext();
  const { setButtonState } = useFlowManagerContext();
  const { t } = useTranslations();

  const { isPending: isPendingLabel, getLabelLink } = useLabelLink();
  const { isError, isPending, isPrintSuccess, printLabelHandler } =
    useLabelCreation();

  const buttonText = isError ? t('tryAgain') : t('print');
  const { image, title } = isPrintSuccess
    ? {
        image: PrintSuccess,
        title: t('labelPrintingPlaceIt'),
      }
    : {
        image: PrintLabeImg,
        title: t('pleasePrintLabel'),
      };

  useEffect(() => {
    getLabelLink();
  }, [getLabelLink]);

  useEffect(() => {
    if (isPrintSuccess) {
      setButtonState({
        text: t('openCell'),
        isActive: true,
        isLoading: false,
        link: { to: Path.PlaceParcel },
      });
    } else {
      setButtonState({
        text: buttonText,
        isActive: true,
        isLoading: isPending || isPendingLabel,
        buttonAction: printLabelHandler,
      });
    }
  }, [
    labelLink,
    isPending,
    buttonText,
    isPendingLabel,
    isPrintSuccess,
    printLabelHandler,
    setButtonState,
    t,
  ]);

  return (
    <>
      {isPending ? (
        <Loading />
      ) : (
        <FlowManager title={t('labelPrinting')}>
          <Image src={image} alt="" width="300" />
          <Title>{title}</Title>
        </FlowManager>
      )}
    </>
  );
}

import { useTranslations } from '@omnipkg/ui-kit-web';

import ParcelLocker from '@src/assets/images/parcel-locker.jpg';
import { ImageStyled } from '@src/theme/common';

import { ErrorIconWrapper, ImageWrapper, LockIcon, Title } from './styled';

export default function NoAvailableCells() {
  const { t } = useTranslations();

  return (
    <>
      <ImageWrapper>
        <ImageStyled src={ParcelLocker} alt={t('noAvailableCells')} />
        <ErrorIconWrapper>
          <LockIcon />
        </ErrorIconWrapper>
      </ImageWrapper>
      <Title>{t('noAvailableCells')}</Title>
    </>
  );
}

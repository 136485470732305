import { SVGPropsExtended } from '../types';

export default function Language({
  title,
  titleId,
  ...props
}: SVGPropsExtended) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      aria-labelledby={titleId}
      {...props}
    >
      {!!title && <title id={titleId}>{title}</title>}
      <path
        d="M12 21C16.9707 21 21 16.9707 21 12C21 7.0293 16.9707 3 12 3C7.0293 3 3 7.0293 3 12C3 16.9707 7.0293 21 12 21Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.40012 3.89999H9.30012C7.54514 9.1574 7.54514 14.8426 9.30012 20.1H8.40012M14.7001 3.89999C16.4551 9.1574 16.4551 14.8426 14.7001 20.1"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.8999 15.6001V14.7001C9.15731 16.455 14.8425 16.455 20.0999 14.7001V15.6001M3.8999 9.30005C9.15731 7.54508 14.8425 7.54508 20.0999 9.30005"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { Button } from '@omnipkg/ui-kit-web';

import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import {
  BottomContainer,
  CenterContainer,
  Title,
  TopContainer,
} from '@src/theme/common';

import LiqPayForm from './components/LiqPayForm/LiqPayForm';
import { FlowManagerProps } from './types';

export default function FlowManager({
  title,
  isHideButton,
  children,
}: FlowManagerProps) {
  const { buttonState, isPayBtn } = useFlowManagerContext();

  return (
    <>
      {title && (
        <TopContainer>
          <Title>{title}</Title>
        </TopContainer>
      )}
      <CenterContainer>{children}</CenterContainer>

      {!isHideButton && (
        <BottomContainer>
          {isPayBtn ? (
            <LiqPayForm />
          ) : (
            <>
              {buttonState.secondButtonText && (
                <Button
                  label={buttonState.secondButtonText}
                  variant="outlined"
                  onClick={buttonState.secondButtonAction}
                />
              )}
              <Button
                label={buttonState.text}
                variant="contained"
                link={buttonState.link}
                isLoading={buttonState.isLoading}
                disabled={!buttonState.isActive}
                onClick={buttonState.buttonAction}
              />
            </>
          )}
        </BottomContainer>
      )}
    </>
  );
}

import { VerticalCenteringWrapper } from '@src/theme/common';

import { MessageProps } from './types';

import { SubTitle, Title } from './styled';

export default function Message({ icon, title, children }: MessageProps) {
  return (
    <VerticalCenteringWrapper>
      {icon}
      <Title>{title}</Title>
      <SubTitle>{children}</SubTitle>
    </VerticalCenteringWrapper>
  );
}

import { useRoutes as useBaseRoutes } from 'react-router-dom';

import MainLayout from '@src/containers/MainLayout';
import AllTariffs from '@src/pages/AllTariffs';
import Authorization from '@src/pages/Authorization';
import ConfirmationCode from '@src/pages/ConfirmationCode';
import DeliveryAddress from '@src/pages/DeliveryAddress';
import DeliveryDetails from '@src/pages/DeliveryDetails';
import Feedback from '@src/pages/Feedback';
import Dislike from '@src/pages/Feedback/components/Dislike';
import Done from '@src/pages/Feedback/components/Done';
import Main from '@src/pages/Main';
import OrderCreated from '@src/pages/OrderCreated/OrderCreated';
import ParcelDescription from '@src/pages/ParcelDescription';
import ParcelRules from '@src/pages/ParcelRules';
import ParcelSize from '@src/pages/ParcelSize';
import PaymentError from '@src/pages/PaymentError';
import PaymentSuccess from '@src/pages/PaymentSuccess';
import PlaceParcel from '@src/pages/PlaceParcel';
import PrintLabel from '@src/pages/PrintLabel';
import PublicOffer from '@src/pages/PublicOffer';
import RecipientDetails from '@src/pages/RecipientDetails';
import SenderDetails from '@src/pages/SenderDetails';
import { FeedbackPath, Path } from '@src/types/routes';

export function useRoutes() {
  return useBaseRoutes([
    {
      path: Path.Main,
      element: <MainLayout />,
      children: [
        {
          path: Path.Main,
          element: <Main />,
        },
        {
          path: Path.DeliveryAddress,
          element: <DeliveryAddress />,
        },
        {
          path: Path.ParcelSize,
          element: <ParcelSize />,
        },
        {
          path: Path.ParcelDescription,
          element: <ParcelDescription />,
        },
        {
          path: Path.AllTariffs,
          element: <AllTariffs />,
        },
        {
          path: Path.DeliveryDetails,
          element: <DeliveryDetails />,
        },
        {
          path: Path.Authorization,
          element: <Authorization />,
        },
        {
          path: Path.PublicOffer,
          element: <PublicOffer />,
        },
        {
          path: Path.ConfirmationCode,
          element: <ConfirmationCode />,
        },
        {
          path: Path.SenderDetails,
          element: <SenderDetails />,
        },
        {
          path: Path.ParcelRules,
          element: <ParcelRules />,
        },
        {
          path: Path.RecipientDetails,
          element: <RecipientDetails />,
        },
        {
          path: Path.OrderCreated,
          element: <OrderCreated />,
        },
        {
          path: Path.PaymentError,
          element: <PaymentError />,
        },
        {
          path: Path.PaymentSuccess,
          element: <PaymentSuccess />,
        },
        {
          path: Path.PrintLabel,
          element: <PrintLabel />,
        },
        {
          path: Path.PlaceParcel,
          element: <PlaceParcel />,
        },
        {
          path: Path.Feedback,
          children: [
            {
              index: true,
              element: <Feedback />,
            },
            {
              path: FeedbackPath.Dislike,
              element: <Dislike />,
            },
            {
              path: FeedbackPath.Done,
              element: <Done />,
            },
          ],
        },
      ],
    },
  ]);
}

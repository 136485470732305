import { Button, useTranslations } from '@omnipkg/ui-kit-web';

import { useGetPayHandler } from './hooks/useGetPayHandler';

import { Form } from './styled';

export default function LiqPayForm() {
  const { t } = useTranslations();

  const { isPayLoading, signature } = useGetPayHandler();

  return (
    <Form
      method="POST"
      action="https://www.liqpay.ua/api/3/checkout"
      acceptCharset="utf-8"
    >
      <input type="hidden" name="data" value={signature?.data} />
      <input type="hidden" name="signature" value={signature?.signature} />
      <Button
        label={t('pay')}
        variant="contained"
        isLoading={!signature || isPayLoading}
      />
    </Form>
  );
}

import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ErrorType,
  useAuthContext,
  useErrorHandlerContext,
} from '@omnipkg/ui-kit-web';

import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useInputFieldsContext } from '@src/contexts/InputFieldsContext';
import { InputName } from '@src/contexts/InputFieldsContext/types';
import { Path } from '@src/types/routes';

import { useCheckCode } from '../utils/checkCode';

interface HookReturns {
  isError: boolean;
  isLoading: boolean;
  checkCodeHandler: (smsCode: string) => void;
}

export function useCheckCodeHandler(): HookReturns {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate();

  const { deviceUid } = useDeviceInfoContext();
  const { inputFields, recordInputFields } = useInputFieldsContext();
  const { handleError } = useErrorHandlerContext();
  const { saveToken } = useAuthContext();

  const checkCode = useCheckCode();

  const checkCodeHandler = useCallback(
    (smsCode: string) => {
      setIsError(false);

      checkCode({
        code: smsCode,
        phone: inputFields.senderFullNumber,
        deviceUid,
      })
        .then(({ first_name, last_name, token_data }) => {
          recordInputFields({
            ...inputFields,
            [InputName.SenderName]: first_name || '',
            [InputName.SenderSurname]: last_name || '',
          });

          saveToken(token_data);

          navigate(Path.SenderDetails);
        })
        .catch((error) => {
          setIsError(true);

          if (error.message === ErrorType.invalidConfirmationCode) {
            return handleError(error.message);
          }
        })
        .finally(() => setIsLoading(false));
    },
    [
      deviceUid,
      inputFields,
      navigate,
      checkCode,
      saveToken,
      handleError,
      recordInputFields,
    ],
  );

  return {
    isError,
    isLoading,
    checkCodeHandler,
  };
}

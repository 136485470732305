import styled, { css } from 'styled-components';

import { H2Bold, Txt2 } from '@src/theme/common';

export const FieldWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: ${({ theme }) => theme.spacing()};

  input[type='text'],
  input[type='number'] {
    ${H2Bold}

    text-align: center;

    &::selection {
      background: transparent;
    }
  }
`;

export const InputWrapper = styled.div`
  ${H2Bold}
  position: relative;

  width: 100%;

  border-width: 2px;
  border-style: solid;
  border-color: #cccccc;
  border-radius: 10px;
  padding: 13px 16px;
  text-align: center;
  text-align: center;
`;

const singStyle = css`
  content: '';
  display: block;

  width: 16px;
  height: 3px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  border-radius: 5px;
  background-color: #cccccc;
`;

export const Button = styled.button`
  width: 50px;
  height: 100%;

  position: absolute;
  top: 50%;
  left: 0;

  border: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: transparent;

  outline: none !important;
  overflow: hidden;

  transform: translateY(-50%);
  text-indent: -9999px;

  z-index: 1;

  &:active {
    background-color: #f3f7fe;
  }

  &::before {
    ${singStyle}
  }

  &[data-action='plus'] {
    left: auto;
    right: 0;

    border-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    &::after {
      ${singStyle}

      width: 3px;
      height: 16px;
    }
  }
`;

export const Label = styled.label`
  ${Txt2}
`;

import { useCallback, useEffect, useState } from 'react';

import { useOrderDataContext } from '@src/contexts/OrderDataContext';

import { useGetProcessingUid } from '../utils/getProcessingUid';

export function useProcessingUid(): {
  isLoading: boolean;
} {
  const [isLoading, setIsLoading] = useState(false);

  const { recordProcessingUid } = useOrderDataContext();
  const { orderData } = useOrderDataContext();

  const getProcessingUid = useGetProcessingUid();

  const getProcessingUidHandler = useCallback(
    (orderUid: string | undefined) => {
      if (!orderUid) return;

      setIsLoading(true);

      getProcessingUid({
        orderUid,
      })
        .then(({ processing_uid }) => {
          if (!processing_uid) {
            throw new Error('processing_uid is empty');
          }

          recordProcessingUid(processing_uid);
        })
        .catch(() => {
          setTimeout(() => {
            getProcessingUidHandler(orderUid);
          }, 6000);
        })
        .finally(() => setIsLoading(false));
    },
    [getProcessingUid, recordProcessingUid],
  );

  useEffect(() => {
    getProcessingUidHandler(orderData.order_uid);
  }, [orderData.order_uid, getProcessingUidHandler]);

  return { isLoading };
}

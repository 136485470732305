import { createGlobalStyle } from 'styled-components';

import { Txt2 } from './common';

export const GlobalStyle = createGlobalStyle`
  body {
    ${Txt2}
    min-height: auto;
  }
`;

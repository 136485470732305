import { ParcelInfo } from './types';

export const DEFAULT_PARCEL_INFO: ParcelInfo = {
  weight: '',
  width: 0,
  height: 0,
  length: 0,
  description: '',
  insurance: '',
};

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslations } from '@omnipkg/ui-kit-web';

import { useDeliveryAddressContext } from '@src/contexts/DeliveryAddressContext';
import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useParcelContext } from '@src/contexts/ParcelContext';
import { Path } from '@src/types/routes';

import { getFullAddress } from '../utils/getFullAddress';

export default function useEditData() {
  const navigate = useNavigate();

  const { deliveryAddress } = useDeliveryAddressContext();
  const { parcelInfo } = useParcelContext();
  const {
    countryMeasurements: { currency, unitOfLength, unitOfWeight },
  } = useDeviceInfoContext();
  const { setIsEditCell, setIsEditInsurance } = useFlowManagerContext();
  const { t } = useTranslations();

  const parcelWeight = parcelInfo.weight;
  const parcelHeight = Math.trunc(parcelInfo.height / 10);
  const parcelWidth = Math.trunc(parcelInfo.width / 10);
  const parcelLength = Math.trunc(parcelInfo.length / 10);
  const parcelCost = `${currency} ${parcelInfo.insurance}`;
  const parcelSize = `${parcelHeight}x${parcelWidth}x${parcelLength} ${unitOfLength} | ${parcelWeight} ${unitOfWeight}`;
  const address = getFullAddress(deliveryAddress) || t('notFound');

  const editData = [
    {
      title: `${t('declared')}: `,
      value: parcelCost,
      action: () => {
        setIsEditInsurance(true);
        navigate(Path.ParcelDescription);
      },
    },
    {
      title: `${t('parcelSize')}: `,
      value: parcelSize,
      action: () => {
        setIsEditCell(true);
        navigate(Path.Main);
      },
    },
    {
      title: `${t('deliveryAddress')}: `,
      value: address,
      action: () => {
        navigate(Path.DeliveryAddress);
      },
    },
  ];

  useEffect(() => {
    setIsEditCell(false);
    setIsEditInsurance(false);
  }, [setIsEditCell, setIsEditInsurance]);

  return editData;
}

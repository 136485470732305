import { SVGIcon, useTranslations } from '@omnipkg/ui-kit-web';

import useEditData from './hooks/useEditData';

import {
  Bold,
  CellDetailsWrapper,
  DeliveryDetailsStyled,
  EditButton,
  EditInfo,
  Text,
  Title,
} from './styled';

export default function Details() {
  const { t } = useTranslations();

  const editData = useEditData();

  return (
    <DeliveryDetailsStyled>
      <Title>{t('details')}:</Title>
      {editData.map(({ title, value, action }) => (
        <CellDetailsWrapper key={title}>
          <EditInfo>
            <Bold>{title}</Bold>
            <Text>{value}</Text>
          </EditInfo>
          <EditButton onClick={action}>
            <SVGIcon name="pencil" size={16} />
          </EditButton>
        </CellDetailsWrapper>
      ))}
    </DeliveryDetailsStyled>
  );
}

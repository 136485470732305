export function parseWeightLimit(weightLimit: string | undefined) {
  const defaultValues = { min: 0.01, max: 30 };

  if (!weightLimit) {
    return defaultValues;
  }

  const values = weightLimit.split('-').map((str) => parseFloat(str.trim()));

  if (values.length !== 2 || values.some(isNaN)) {
    return defaultValues;
  }

  const [min, max] = values;
  return { min, max };
}

import { useCallback, useState } from 'react';
import { useAuthContext } from '@omnipkg/ui-kit-web';

import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useOrderDataContext } from '@src/contexts/OrderDataContext';

import { usePrintLabel } from '../utils/printLabel';

interface HookReturn {
  isError: boolean;
  isPending: boolean;
  isPrintSuccess: boolean;
  printLabelHandler: () => void;
}

const PROJECT = process.env.PROJECT_PRINT;

export function useLabelCreation(): HookReturn {
  const [isError, setIsError] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isPrintSuccess, setIsPrintSuccess] = useState(false);

  const { token } = useAuthContext();
  const { deviceInfo } = useDeviceInfoContext();
  const { labelLink } = useOrderDataContext();

  const deviceId = deviceInfo?.device;

  const printLabel = usePrintLabel();

  const printLabelHandler = useCallback(() => {
    if (!deviceId || !labelLink) return;

    setIsError(false);
    setIsPending(true);

    printLabel({
      token,
      deviceId,
      labelLink,
      project: PROJECT as string,
    })
      .then(() => {
        setIsPrintSuccess(true);
      })
      .catch(() => {
        // TODO: remove this line after fix
        setIsPrintSuccess(true);
        // handleError(error.message);
        //setIsError(true);
      })
      .finally(() => setIsPending(false));
  }, [token, deviceId, labelLink, printLabel]);

  return { isError, isPrintSuccess, isPending, printLabelHandler };
}

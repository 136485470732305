import { SVGPropsExtended } from '../types';

export default function Success({
  title,
  titleId,
  ...props
}: SVGPropsExtended) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="101"
      viewBox="0 0 100 101"
      fill="none"
      aria-labelledby={titleId}
      {...props}
    >
      {!!title && <title id={titleId}>{title}</title>}

      <rect y="0.5" width="100" height="100" rx="50" fill="#00BD1E" />
      <path
        d="M29 51.0172L42 65.5L71 35.5"
        stroke="white"
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { RadioButtons, TextArea, useTranslations } from '@omnipkg/ui-kit-web';

import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useOnConfirm } from '@src/pages/Feedback/hooks/useOnConfirm';

import { useReasons } from './hooks/useReasons';

import { ReasonsWrapper } from './styled';

export default function Reasons(): JSX.Element {
  const [selectedReason, setSelectedReason] = useState('');
  const [additionalComments, setAdditionalComments] = useState('');
  const [showOtherInput, setShowOtherInput] = useState(false);

  const { t } = useTranslations();

  const reasons = useReasons();
  const { isPending, onConfirm } = useOnConfirm();

  const isActive =
    (selectedReason !== '' && selectedReason !== t('other')) ||
    additionalComments !== '';

  const handleReasonChange = (event: ChangeEvent<HTMLFormElement>) => {
    const value = event.target.value;

    setSelectedReason(value);

    setShowOtherInput(value === t('other'));
  };

  const handleCommentsChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setAdditionalComments(event.target.value);
  };

  const buttonAction = useCallback(() => {
    onConfirm(selectedReason);

    setSelectedReason('');
    setAdditionalComments('');
    setShowOtherInput(false);
  }, [selectedReason, onConfirm]);

  const { setButtonState } = useFlowManagerContext();

  useEffect(() => {
    setButtonState({
      text: t('submit'),
      isActive,
      isLoading: isPending,
      buttonAction,
    });
  }, [isActive, isPending, buttonAction, setButtonState, t]);

  return (
    <ReasonsWrapper>
      <RadioButtons values={reasons} onChange={handleReasonChange} />

      {showOtherInput && (
        <TextArea
          value={additionalComments}
          placeholder={t('describeReason')}
          onChange={handleCommentsChange}
        />
      )}
    </ReasonsWrapper>
  );
}

import styled from 'styled-components';

import LockSVG from '@src/assets/icons/lock.svg';

export const ImageWrapper = styled.div`
  max-width: 330px;

  position: relative;
`;

export const ErrorIconWrapper = styled.div`
  width: 100px;
  height: 100px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(204, 0, 0, 0.8);
  border-radius: 50%;

  z-index: 2;
`;

export const LockIcon = styled(LockSVG)`
  path {
    stroke: ${({ theme }) => theme.palette.grey[0]};
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 24px;
  font-weight: ${({ theme }) => theme.typography.fontWeight[400]};
  line-height: 140%;

  margin-top: ${({ theme }) => theme.spacing(2.5)};
  margin-bottom: ${({ theme }) => theme.spacing(2.5)};
`;

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { StorageKey } from '@src/constants/storageKey';
import { useSessionStorage } from '@src/utils/useStorage';

import { ContextProps } from '../types';
import { DEFAULT_PARCEL_INFO } from './constants';
import { ParcelContextValue, ParcelInfo } from './types';

const ParcelContext = createContext<ParcelContextValue | null>(null);

export function ParcelContextProvider({ children }: ContextProps): JSX.Element {
  const [parcelInfo, setState] = useState<ParcelInfo>(DEFAULT_PARCEL_INFO);

  function updateUserSession<T>(param: { [key: string]: T }) {
    const initParcelInfo = useSessionStorage.get<ParcelInfo>(
      StorageKey.ParcelInfo,
    );

    useSessionStorage.save(StorageKey.ParcelInfo, {
      ...initParcelInfo,
      ...param,
    });
  }

  const setParcelInfo = useCallback(
    (type: keyof ParcelInfo, value: number | string) => {
      updateUserSession({ [type]: value });

      setState((prevState) => ({
        ...prevState,
        [type]: value,
      }));
    },
    [],
  );

  const clearParcelInfo = useCallback(() => {
    setState(DEFAULT_PARCEL_INFO);
    useSessionStorage.remove(StorageKey.ParcelInfo);
  }, []);

  useEffect(() => {
    const initParcelInfo = useSessionStorage.get<ParcelInfo>(
      StorageKey.ParcelInfo,
    );

    if (initParcelInfo) {
      setState(initParcelInfo);
    }
  }, []);

  const value: ParcelContextValue = useMemo(
    () => ({
      parcelInfo,
      setParcelInfo,
      clearParcelInfo,
    }),
    [parcelInfo, setParcelInfo, clearParcelInfo],
  );

  return (
    <ParcelContext.Provider value={value}>{children}</ParcelContext.Provider>
  );
}

export function useParcelContext(): ParcelContextValue {
  const value = useContext(ParcelContext);

  if (value) return value;

  throw new Error(
    'Please, use useParcelContext hook inside ParcelContextProvider',
  );
}

import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useErrorHandlerContext } from '@omnipkg/ui-kit-web';

import { useOrderDataContext } from '@src/contexts/OrderDataContext';
import { Path } from '@src/types/routes';

import {
  GetOrderExtendingResponse,
  useGetOrderExtending,
} from '../utils/GetOrderExtending';

interface HookReturn {
  isPayLoading: boolean;
  signature?: GetOrderExtendingResponse;
  getSignature: () => void;
}

export function useGetPayHandler(): HookReturn {
  const [isPayLoading, setIsPayLoading] = useState(true);
  const [signature, setSignature] = useState<
    GetOrderExtendingResponse | undefined
  >();

  const navigate = useNavigate();

  const { processingUid } = useOrderDataContext();
  const { handleError } = useErrorHandlerContext();

  const getOrderExtending = useGetOrderExtending();

  const getSignature = useCallback(() => {
    if (!processingUid) return;

    const resultUrl = window.location.href;

    setIsPayLoading(true);

    getOrderExtending({
      processingUid: processingUid,
      resultUrl,
    })
      .then((data) => {
        setSignature(data);
      })
      .catch((error) => {
        // TODO: add to ui-kit-web
        if (error.message === 'order_has_already_paid') {
          return navigate(Path.PaymentSuccess);
        }

        handleError(error);
      })
      .finally(() => setIsPayLoading(false));
  }, [processingUid, getOrderExtending, handleError, navigate]);

  useEffect(() => {
    getSignature();
  }, [getSignature]);

  return { isPayLoading, signature, getSignature };
}

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { StorageKey } from '@src/constants/storageKey';
import { useSessionStorage } from '@src/utils/useStorage';

import { ContextProps } from '../types';
import { DEFAULT_FIELDS_VALUES } from './constants';
import { InputFields, InputFieldsContextValue } from './types';

const InputFieldsContext = createContext<InputFieldsContextValue | null>(null);

export function InputFieldsContextProvider({
  children,
}: ContextProps): JSX.Element {
  const [inputFields, setInputFields] = useState<InputFields>(
    DEFAULT_FIELDS_VALUES,
  );

  useEffect(() => {
    const data = useSessionStorage.get<InputFields>(StorageKey.InputFields);

    if (data) {
      setInputFields(data);
    }
  }, []);

  const recordInputFields = useCallback((data: InputFields) => {
    useSessionStorage.save(StorageKey.InputFields, data);
    setInputFields(data);
  }, []);

  const clearInputFields = useCallback(() => {
    setInputFields(DEFAULT_FIELDS_VALUES);
    useSessionStorage.remove(StorageKey.InputFields);
  }, []);

  const value: InputFieldsContextValue = useMemo(
    () => ({
      inputFields,
      recordInputFields,
      clearInputFields,
    }),
    [inputFields, recordInputFields, clearInputFields],
  );

  return (
    <InputFieldsContext.Provider value={value}>
      {children}
    </InputFieldsContext.Provider>
  );
}

export function useInputFieldsContext(): InputFieldsContextValue {
  const value = useContext(InputFieldsContext);

  if (value) return value;

  throw new Error(
    'Please, use useInputFieldsContext hook inside InputFieldsContextProvider',
  );
}

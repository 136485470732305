import { styled } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing()};

  margin-top: ${({ theme }) => theme.spacing(4)};
`;

export const Text = styled.p``;

import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ErrorType,
  OpenCell,
  useErrorHandlerContext,
} from '@omnipkg/ui-kit-web';

import { useDeliveryAddressContext } from '@src/contexts/DeliveryAddressContext';
import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useInputFieldsContext } from '@src/contexts/InputFieldsContext';
import { useOrderDataContext } from '@src/contexts/OrderDataContext';
import { useParcelContext } from '@src/contexts/ParcelContext';
import { useTariffsContext } from '@src/contexts/TariffsContext';
import { Path } from '@src/types/routes';

import { usePlaceParcel } from '../utils/placeParcel';

interface UsePlaceParcelHandlerReturn {
  isPending: boolean;
  openedCell: OpenCell[];
  isThereAreOpenCells: boolean;
  placeParcelHandler: (args?: OpenCellArgs) => void;
}

interface OpenCellArgs {
  ignoreOpenCells?: boolean;
}

export function usePlaceParcelHandler(): UsePlaceParcelHandlerReturn {
  const [isThereAreOpenCells, setIsThereAreOpenCells] = useState(false);
  const [openedCell, setOpenedCell] = useState<OpenCell[]>([]);
  const [isPending, setIsPending] = useState(false);

  const navigate = useNavigate();

  const { deviceInfo, recordOpenedCell, clearSelectCell } =
    useDeviceInfoContext();
  const { processingUid } = useOrderDataContext();
  const { handleError } = useErrorHandlerContext();
  const { clearParcelInfo } = useParcelContext();
  const { clearInputFields } = useInputFieldsContext();
  const { clearDeliveryAddress } = useDeliveryAddressContext();
  const { clearSelectedTariff } = useTariffsContext();

  const openDeviceCell = usePlaceParcel();

  const clearData = useCallback(() => {
    clearParcelInfo();
    clearSelectCell();
    clearInputFields();
    clearSelectedTariff();
    clearDeliveryAddress();
  }, [
    clearParcelInfo,
    clearSelectCell,
    clearDeliveryAddress,
    clearSelectedTariff,
    clearInputFields,
  ]);

  const placeParcelHandler = useCallback(
    (args?: OpenCellArgs) => {
      if (!deviceInfo || !processingUid) return;

      setIsThereAreOpenCells(false);

      setIsPending(true);
      openDeviceCell({
        deviceUid: deviceInfo.uid,
        orderUid: processingUid,
        ignoreOpenCells: args?.ignoreOpenCells,
      })
        .then((extra) => {
          const openCell = {
            cell: extra?.cell,
            stack: extra?.stack,
          } as OpenCell;

          if (extra) {
            recordOpenedCell(extra);
          }
          setOpenedCell([openCell]);
          clearData();
          navigate(Path.PlaceParcel);
        })
        .catch((error) => {
          if (error.message === ErrorType.thereAreOpenCells) {
            setOpenedCell(error.stack.extra.opened_cells);
            return setIsThereAreOpenCells(true);
          }

          handleError(error.message);
        })
        .finally(() => setIsPending(false));
    },
    [
      deviceInfo,
      processingUid,
      navigate,
      clearData,
      handleError,
      openDeviceCell,
      recordOpenedCell,
    ],
  );

  return {
    isPending,
    openedCell,
    isThereAreOpenCells,
    placeParcelHandler,
  };
}

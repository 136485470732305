import { useNavigate } from 'react-router-dom';
import { SVGIcon, useTranslations } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import { FeedbackPath } from '@src/types/routes';

import { useOnConfirm } from './hooks/useOnConfirm';

import { ButtonWrapper, RateButtonsWrapper, RateTitle } from './styled';

export default function Feedback(): JSX.Element {
  const navigate = useNavigate();

  const { t } = useTranslations();

  const { isPending, onConfirm } = useOnConfirm();

  const goToLikeRate = () => onConfirm();

  const goToDislikeRate = () => navigate(FeedbackPath.Dislike);

  return (
    <FlowManager title={t('shipmentAccepted')}>
      <RateButtonsWrapper>
        <RateTitle>{t('rateQuality')}</RateTitle>
        <ButtonWrapper
          type="button"
          aria-label={t('tapLike')}
          onClick={goToLikeRate}
          disabled={isPending}
        >
          <SVGIcon name="like-face" fillVariant="primary" size={100} />
        </ButtonWrapper>
        <ButtonWrapper
          type="button"
          aria-label={t('tapDislike')}
          onClick={goToDislikeRate}
          disabled={isPending}
        >
          <SVGIcon name="dislike-face" fillVariant="error" size={100} />
        </ButtonWrapper>
      </RateButtonsWrapper>
    </FlowManager>
  );
}

import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CourierPoint, LocationPoint } from '@omnic/widget-locations';
import {
  ErrorType,
  useAuthContext,
  useErrorHandlerContext,
} from '@omnipkg/ui-kit-web';

import { useDeliveryAddressContext } from '@src/contexts/DeliveryAddressContext';
import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useInputFieldsContext } from '@src/contexts/InputFieldsContext';
import { useOrderDataContext } from '@src/contexts/OrderDataContext';
import { useParcelContext } from '@src/contexts/ParcelContext';
import { useTariffsContext } from '@src/contexts/TariffsContext';
import { Path } from '@src/types/routes';

import { useCreateOrder } from '../utils/createOrder';

export function useCreateOrderHandler(): {
  isError: boolean;
  isLoading: boolean;
  createOrderHandler: () => void;
} {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const { token } = useAuthContext();
  const { deviceInfo } = useDeviceInfoContext();
  const { deliveryAddress } = useDeliveryAddressContext();
  const { selectedTariff } = useTariffsContext();
  const { parcelInfo } = useParcelContext();
  const { inputFields } = useInputFieldsContext();
  const { isRecipientPays } = useOrderDataContext();
  const { recordOrderData } = useOrderDataContext();
  const { handleError } = useErrorHandlerContext();

  const createOrder = useCreateOrder();

  const getRequestData = (data: LocationPoint | CourierPoint) => {
    if ('uid' in data) {
      return {
        point: {
          uid: data.uid,
        },
      };
    }

    return {
      countryCode: data.address.country_code,
      region: data.address.region,
      city: data.address.city,
      address: data.address.address,
    };
  };

  const createOrderHandler = useCallback(() => {
    setIsError(false);

    if (!deviceInfo || !deliveryAddress || !selectedTariff) return;

    const requestData = getRequestData(deliveryAddress);

    setIsLoading(true);

    createOrder({
      bearerToken: token,
      number: String(Date.now()),
      declaredCost: Number(parcelInfo.insurance),
      height: parcelInfo.height,
      width: parcelInfo.width,
      length: parcelInfo.length,
      weight: Number(parcelInfo.weight),
      tariffCode: selectedTariff.code,
      sum: isRecipientPays
        ? 0
        : selectedTariff.delivery_cost +
          selectedTariff.additional_services_cost,
      senderFirstName: inputFields.senderName,
      senderLastName: inputFields.senderSurname,
      senderPhone: inputFields.senderFullNumber,
      recipientFirstName: inputFields.recipientName,
      recipientLastName: inputFields.recipientSurname,
      recipientPhone: inputFields.recipientFullNumber,
      from: {
        code: deviceInfo.device,
        brand: deviceInfo.network,
      },
      to: requestData,
    })
      .then((data) => {
        recordOrderData(data);
        navigate(Path.OrderCreated);
      })
      .catch((error) => {
        if (error.message === ErrorType.permissionDenied) {
          setIsError(true);
        }

        handleError(error.message);
      })
      .finally(() => setIsLoading(false));
  }, [
    token,
    deviceInfo,
    selectedTariff,
    isRecipientPays,
    deliveryAddress,
    parcelInfo.weight,
    parcelInfo.width,
    parcelInfo.height,
    parcelInfo.length,
    parcelInfo.insurance,
    inputFields.senderName,
    inputFields.senderSurname,
    inputFields.senderFullNumber,
    inputFields.recipientName,
    inputFields.recipientSurname,
    inputFields.recipientFullNumber,
    navigate,
    createOrder,
    handleError,
    recordOrderData,
  ]);

  return { isError, isLoading, createOrderHandler };
}

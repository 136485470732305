import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { CourierPoint, LocationPoint } from '@omnic/widget-locations';

import { StorageKey } from '@src/constants/storageKey';
import { useSessionStorage } from '@src/utils/useStorage';

import { ContextProps } from '../types';
import { DeliveryAddressContextValue } from './types';

const DeliveryAddressContext =
  createContext<DeliveryAddressContextValue | null>(null);

export function DeliveryAddressContextProvider({
  children,
}: ContextProps): JSX.Element {
  const [deliveryAddress, setDeliveryAddress] = useState<
    LocationPoint | CourierPoint
  >();

  useEffect(() => {
    const data = useSessionStorage.get(
      StorageKey.DeliveryAddress,
    ) as LocationPoint;

    if (data) {
      setDeliveryAddress(data);
    }
  }, []);

  const recordDeliveryAddress = useCallback(
    (data: LocationPoint | CourierPoint) => {
      useSessionStorage.save(StorageKey.DeliveryAddress, data);
      setDeliveryAddress(data);
    },
    [],
  );

  const clearDeliveryAddress = useCallback(() => {
    setDeliveryAddress(undefined);
    useSessionStorage.remove(StorageKey.DeliveryAddress);
  }, []);

  const value: DeliveryAddressContextValue = useMemo(
    () => ({
      deliveryAddress,
      recordDeliveryAddress,
      clearDeliveryAddress,
    }),
    [deliveryAddress, recordDeliveryAddress, clearDeliveryAddress],
  );

  return (
    <DeliveryAddressContext.Provider value={value}>
      {children}
    </DeliveryAddressContext.Provider>
  );
}

export function useDeliveryAddressContext(): DeliveryAddressContextValue {
  const value = useContext(DeliveryAddressContext);

  if (value) return value;

  throw new Error(
    'Please, use useDeliveryAddressContext hook inside DeliveryAddressContextProvider',
  );
}

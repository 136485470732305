import { CourierPoint, LocationPoint } from '@omnic/widget-locations';

export const getFullAddress = (
  data: LocationPoint | CourierPoint | undefined,
) => {
  if (!data) return;

  if ('type' in data) {
    return data.location.full_address;
  }

  return data.address.address_full;
};

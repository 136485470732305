import { useTranslations } from '@omnipkg/ui-kit-web';

import { DeliveryTimeProps } from './types';

export default function DeliveryTime({ minDays, maxDays }: DeliveryTimeProps) {
  const { t } = useTranslations();

  let deliveryTime = '';

  if (!minDays || !maxDays) return t('notFound');

  if (minDays === maxDays) {
    deliveryTime = `${minDays} ${t('day')}`;
  } else {
    deliveryTime = `${minDays} - ${maxDays} ${t('days')}`;
  }

  return deliveryTime;
}

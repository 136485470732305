import styled, { css } from 'styled-components';

import { StyledWrapper as StyledWrapperBase, Txt2 } from '@src/theme/common';

import { StyledWrapperProps } from './types';

function applyStyledWrapper({ $isError }: StyledWrapperProps) {
  return $isError
    ? css`
        border-color: ${({ theme }) => theme.palette.error[100]};
      `
    : css``;
}

const resendText = css`
  ${Txt2};
  color: ${({ theme }) => theme.palette.grey[60]};
  text-align: center;
`;

export const StyledWrapper = styled(StyledWrapperBase)<StyledWrapperProps>`
  flex-direction: column;

  padding: ${({ theme }) => theme.spacing(3)};

  ${applyStyledWrapper};

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(4)};
  }
`;

export const ResendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.p`
  ${resendText};
`;

export const Button = styled.button`
  ${resendText};

  background-color: transparent;
  border: none;

  outline: none;

  margin-top: ${({ theme }) => theme.spacing(0.5)};
`;

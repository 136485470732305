import { useEffect } from 'react';
import { useTranslations } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import Message from '@src/components/Message';
import Error from '@src/components/SVGIcon/icons/Error';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { Path } from '@src/types/routes';

export default function PaymentError(): JSX.Element {
  const { setButtonState } = useFlowManagerContext();
  const { t } = useTranslations();

  useEffect(() => {
    setButtonState({
      text: t('tryAgain'),
      isActive: true,
      link: { to: Path.OrderCreated },
    });
  }, [setButtonState, t]);

  return (
    <FlowManager>
      <Message icon={<Error />} title={t('paymentHasNotBeenMade')}>
        {t('errorDataExchange')} <br />
        {t('tryPayAgain')}
      </Message>
    </FlowManager>
  );
}

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { StorageKey } from '@src/constants/storageKey';
import { useSessionStorage } from '@src/utils/useStorage';

import { ContextProps } from '../types';
import { OrderData, OrderDataContextValue } from './types';

const OrderDataContext = createContext<OrderDataContextValue | null>(null);

export function OrderDataContextProvider({
  children,
}: ContextProps): JSX.Element {
  const [isRecipientPays, setIsRecipientPays] = useState(false);
  const [labelLink, setLabelLink] = useState('');
  const [processingUid, setProcessingUid] = useState('');
  const [orderData, setState] = useState<OrderData>({
    number: '',
    fullNumber: '',
    sender: {
      name: '',
      secondName: '',
      phone: '',
    },
    receiver: {
      name: '',
      secondName: '',
      phone: '',
    },
  });

  function updateUserSession<T>(param: { [key: string]: T }) {
    const initOrderData = useSessionStorage.get<OrderData>(
      StorageKey.OrderData,
    );

    useSessionStorage.save(StorageKey.OrderData, {
      ...initOrderData,
      ...param,
      sender: {
        ...initOrderData?.sender,
        ...param?.sender,
      },
      receiver: {
        ...initOrderData?.receiver,
        ...param?.receiver,
      },
    });
  }

  const setOrderData = useCallback((type: keyof OrderData, value: string) => {
    updateUserSession({ [type]: value });

    setState((prevState) => ({
      ...prevState,
      [type]: value,
    }));
  }, []);

  const recordOrderData = useCallback((data: OrderData) => {
    useSessionStorage.save(StorageKey.OrderData, data);
    setState(data);
  }, []);

  const recordProcessingUid = useCallback((uid: string) => {
    useSessionStorage.save(StorageKey.ProcessingUid, uid);
    setProcessingUid(uid);
  }, []);

  const recordLabelLink = useCallback((link: string) => {
    useSessionStorage.save(StorageKey.LabelLink, link);
    setLabelLink(link);
  }, []);

  const recordWhoPays = useCallback((data: boolean) => {
    useSessionStorage.save(StorageKey.IsRecipientPays, data);
    setIsRecipientPays(data);
  }, []);

  useEffect(() => {
    const initOrderData = useSessionStorage.get<OrderData>(
      StorageKey.OrderData,
    );
    const savedLabelLink = useSessionStorage.get<string>(StorageKey.LabelLink);
    const savedProcessingUid = useSessionStorage.get<string>(
      StorageKey.ProcessingUid,
    );
    const savedIsRecipientPays = useSessionStorage.get<boolean>(
      StorageKey.IsRecipientPays,
    );

    if (initOrderData) {
      setState(initOrderData);
    }

    if (savedLabelLink) {
      setLabelLink(savedLabelLink);
    }

    if (savedProcessingUid) {
      setProcessingUid(savedProcessingUid);
    }

    if (savedIsRecipientPays) {
      setIsRecipientPays(savedIsRecipientPays);
    }
  }, []);

  const value: OrderDataContextValue = useMemo(
    () => ({
      orderData,
      labelLink,
      processingUid,
      isRecipientPays,
      setOrderData,
      recordWhoPays,
      recordOrderData,
      recordLabelLink,
      recordProcessingUid,
    }),
    [
      orderData,
      labelLink,
      processingUid,
      isRecipientPays,
      setOrderData,
      recordWhoPays,
      recordOrderData,
      recordLabelLink,
      recordProcessingUid,
    ],
  );

  return (
    <OrderDataContext.Provider value={value}>
      {children}
    </OrderDataContext.Provider>
  );
}

export function useOrderDataContext(): OrderDataContextValue {
  const value = useContext(OrderDataContext);

  if (value) return value;

  throw new Error(
    'Please, use useOrderDataContext hook inside OrderDataContextProvider',
  );
}

import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';

interface GetProcessingUidArgs {
  orderUid: string;
}

interface GetProcessingUidResponse {
  processing_uid: string;
}

const baseURL = process.env.API_ECOM;

export function useGetProcessingUid() {
  const { request } = useApiContext();

  return useCallback(
    async ({
      orderUid,
    }: GetProcessingUidArgs): Promise<GetProcessingUidResponse> => {
      const { data } = await request<GetProcessingUidResponse>({
        baseURL,
        url: `/public/order?order_uid=${orderUid}`,
      });

      return data;
    },
    [request],
  );
}

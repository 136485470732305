import { useCallback, useEffect, useState } from 'react';
import { useErrorHandlerContext, useTranslations } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useInputFieldsContext } from '@src/contexts/InputFieldsContext';
import { useGetConfirmationCode } from '@src/hooks/useGetConfirmationCode';
import { SubTitle, Title } from '@src/theme/common';

import AccessCode from './components/AccessCode';
import { CODE_LENGTH } from './components/AccessCode/constants';
import { useCheckCodeHandler } from './hooks/useCheckCodeHandler';

import { Button, ResendWrapper, StyledWrapper, Text } from './styled';

export default function ConfirmationCode() {
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');

  const { deviceUid } = useDeviceInfoContext();
  const { inputFields } = useInputFieldsContext();
  const { setButtonState } = useFlowManagerContext();
  const { handleError } = useErrorHandlerContext();
  const { t } = useTranslations();
  const getConfirmationCode = useGetConfirmationCode();
  const { isError: isErrorCode, checkCodeHandler } = useCheckCodeHandler();

  const onChangeCodeHandler = useCallback(() => {
    checkCodeHandler(confirmationCode);
  }, [confirmationCode, checkCodeHandler]);

  const resendCodeHandler = useCallback(() => {
    setIsLoading(true);

    getConfirmationCode({
      phone: inputFields.senderFullNumber,
      deviceUid: deviceUid,
    })
      .catch((error) => handleError(error.message))
      .finally(() => setIsLoading(false));
  }, [
    deviceUid,
    inputFields.senderFullNumber,
    handleError,
    getConfirmationCode,
  ]);

  useEffect(() => {
    setIsError(false);

    const isValidConfirmationCode = confirmationCode.length === CODE_LENGTH;

    if (isValidConfirmationCode) {
      setIsActive(true);
      onChangeCodeHandler();
    }
  }, [confirmationCode, onChangeCodeHandler]);

  useEffect(() => {
    setIsError(isErrorCode);
  }, [isErrorCode]);

  useEffect(() => {
    setButtonState({
      text: t('submit'),
      isLoading,
      isActive: isActive && !isError,
      buttonAction: onChangeCodeHandler,
    });
  }, [isActive, isLoading, isError, onChangeCodeHandler, setButtonState, t]);

  return (
    <FlowManager title={t('phoneNumberConfirmation')}>
      <SubTitle>{`${t('codeWasSentTo')} ${
        inputFields.senderFullNumber
      }`}</SubTitle>

      <StyledWrapper $isError={isError}>
        <Title>{t('codeFromSMS')}:</Title>
        <AccessCode onChangeCode={setConfirmationCode} />
      </StyledWrapper>

      <ResendWrapper>
        <Text>{t('noSMSCode')}</Text>
        <Button onClick={resendCodeHandler}>{t('resendCode')}</Button>
      </ResendWrapper>
    </FlowManager>
  );
}

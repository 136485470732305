import { useNavigate } from 'react-router-dom';
import { SVGIcon } from '@omnipkg/ui-kit-web';

import Language from '../SVGIcon/icons/Language';

import { Button, HeaderWrapper, Logo } from './styled';

export default function Header() {
  const navigate = useNavigate();

  function onBack() {
    navigate(-1);
  }

  return (
    <HeaderWrapper>
      <Button type="button" onClick={onBack}>
        <SVGIcon name="chevron" />
      </Button>
      <Logo>
        <SVGIcon name="omnic-logo" />
      </Logo>
      <Language />
    </HeaderWrapper>
  );
}

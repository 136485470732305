import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Extra } from '@omnipkg/ui-kit-web';

import { StorageKey } from '@src/constants/storageKey';
import { useSessionStorage } from '@src/utils/useStorage';

import { ContextProps } from '../types';
import { DEFAULT_COUNTRY_MEASUREMENTS } from './constants';
import {
  CountryMeasurements,
  CountrySettings,
  Currency,
  DeviceInfoContextValue,
  DeviceInfoResponse,
  ExtendedCellsInfo,
} from './types';

const DeviceInfoContext = createContext<DeviceInfoContextValue | null>(null);

export function DeviceInfoContextProvider({
  children,
}: ContextProps): JSX.Element {
  const [deviceUid, setDeviceUid] = useState('');
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfoResponse>();
  const [selectCell, setSelectCell] = useState<ExtendedCellsInfo>();
  const [openedCell, setOpenedCell] = useState<Extra>();
  const [countryMeasurements, setCountryMeasurements] =
    useState<CountryMeasurements>(DEFAULT_COUNTRY_MEASUREMENTS);

  const convertCurrency = useCallback((currencyName: Currency) => {
    const currencySymbols = {
      [Currency.USD]: '$',
      [Currency.EUR]: '€',
      [Currency.UAH]: '₴',
      [Currency.SAR]: '﷼',
    };

    return currencySymbols[currencyName];
  }, []);

  const setCountryMeasurementsHandler = useCallback(
    (country: CountrySettings) => {
      setCountryMeasurements({
        unitOfLength: country.default_measurement_length,
        unitOfWeight: country.default_measurement_weight,
        currency: convertCurrency(country.currency),
      });
    },
    [convertCurrency],
  );

  const recordDeviceInfo = useCallback(
    (data: DeviceInfoResponse) => {
      setDeviceInfo(data);
      setCountryMeasurementsHandler(data.location.country_settings);
    },
    [setCountryMeasurementsHandler],
  );

  const recordOpenedCell = useCallback((cell: Extra) => {
    setOpenedCell(cell);
    useSessionStorage.save(StorageKey.OpenedCell, cell);
  }, []);

  const clearSelectCell = useCallback(() => {
    setSelectCell(undefined);
    useSessionStorage.remove(StorageKey.SelectedCell);
  }, []);

  useEffect(() => {
    const initDeviceUid = useSessionStorage.get<string>(StorageKey.DeviceUid);
    const initDeviceInfo = useSessionStorage.get<DeviceInfoResponse>(
      StorageKey.DeviceInfo,
    );
    const initSelectedCell = useSessionStorage.get<ExtendedCellsInfo>(
      StorageKey.SelectedCell,
    );

    if (initDeviceUid) {
      setDeviceUid(initDeviceUid);
    }

    if (initDeviceInfo) {
      recordDeviceInfo(initDeviceInfo);
    }

    if (initSelectedCell) {
      setSelectCell(initSelectedCell);
    }
  }, [recordDeviceInfo]);

  useEffect(() => {
    if (deviceInfo) useSessionStorage.save(StorageKey.DeviceInfo, deviceInfo);
  }, [deviceInfo]);

  useEffect(() => {
    if (selectCell) useSessionStorage.save(StorageKey.SelectedCell, selectCell);
  }, [selectCell]);

  const value: DeviceInfoContextValue = useMemo(
    () => ({
      deviceInfo,
      deviceUid,
      selectCell,
      openedCell,
      countryMeasurements,
      setDeviceUid,
      setSelectCell,
      clearSelectCell,
      recordOpenedCell,
      recordDeviceInfo,
    }),
    [
      deviceInfo,
      deviceUid,
      selectCell,
      openedCell,
      countryMeasurements,
      setDeviceUid,
      setSelectCell,
      clearSelectCell,
      recordOpenedCell,
      recordDeviceInfo,
    ],
  );

  return (
    <DeviceInfoContext.Provider value={value}>
      {children}
    </DeviceInfoContext.Provider>
  );
}

export function useDeviceInfoContext(): DeviceInfoContextValue {
  const value = useContext(DeviceInfoContext);

  if (value) return value;

  throw new Error(
    'Please, use useDeviceInfoContext hook inside DeviceInfoContextProvider',
  );
}

import { createContext, useContext, useMemo, useState } from 'react';

import { ContextProps } from '../types';
import { ButtonState, FlowManagerContextValue } from './types';

const FlowManagerContext = createContext<FlowManagerContextValue | null>(null);

export function FlowManagerContextProvider({
  children,
}: ContextProps): JSX.Element {
  const [buttonState, setButtonState] = useState<ButtonState>({
    text: 'Next',
  });
  const [isEditInsurance, setIsEditInsurance] = useState(false);
  const [isEditCell, setIsEditCell] = useState(false);
  const [isPayBtn, setIsPayBtn] = useState(false);

  const value = useMemo(
    () => ({
      isPayBtn,
      isEditCell,
      buttonState,
      isEditInsurance,
      setIsPayBtn,
      setIsEditCell,
      setButtonState,
      setIsEditInsurance,
    }),
    [
      isPayBtn,
      isEditCell,
      buttonState,
      isEditInsurance,
      setIsPayBtn,
      setIsEditCell,
      setButtonState,
      setIsEditInsurance,
    ],
  );

  return (
    <FlowManagerContext.Provider value={value}>
      {children}
    </FlowManagerContext.Provider>
  );
}

export function useFlowManagerContext(): FlowManagerContextValue {
  const value = useContext(FlowManagerContext);

  if (!value) {
    throw new Error(
      'useFlowManagerContext must be used within FlowManagerContextProvider',
    );
  }

  return value;
}

import { StorageKey } from '@src/constants/storageKey';

export const useSessionStorage = Object.freeze({
  save: <T>(key: StorageKey, value: T): void => {
    const serializedValue = JSON.stringify(value);

    sessionStorage.setItem(key, serializedValue);
  },

  get: <T>(key: StorageKey): T | null => {
    const serializedValue = sessionStorage.getItem(key);

    if (!serializedValue) {
      return null;
    }

    return JSON.parse(serializedValue);
  },

  remove: (key: StorageKey): void => {
    sessionStorage.removeItem(key);
  },
});

import styled from 'styled-components';

import { Txt1 } from '@src/theme/common';

export const Text = styled.p`
  ${Txt1}

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

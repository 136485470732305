import { SVGPropsExtended } from '../types';

export default function StandardDelivery({
  title,
  titleId,
  ...props
}: SVGPropsExtended) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      aria-labelledby={titleId}
      {...props}
    >
      {!!title && <title id={titleId}>{title}</title>}
      <path
        d="M19.4333 7.60757L10.3575 12.2542L6.75 10.4792L15.6658 6.07674C15.8658 5.97507 16.1058 5.97507 16.3158 6.07674L19.4333 7.60757Z"
        fill="#1760EE"
      />
      <path
        d="M25.2402 10.4794L16.0052 15.0544L12.5469 13.3502L12.0469 13.096L21.1327 8.4502L21.6327 8.70353L25.2402 10.4794Z"
        fill="#1760EE"
      />
      <path
        d="M15.265 16.3724L15.255 25.9999L6.41 21.384C6.16 21.2524 6 20.9882 6 20.704V11.7974L9.74833 13.644V16.8907C9.74833 17.3065 10.0883 17.6515 10.4983 17.6515C10.9083 17.6515 11.2483 17.3065 11.2483 16.8907V14.3949L11.7483 14.6382L15.265 16.3724Z"
        fill="#1760EE"
      />
      <path
        d="M25.9899 11.8076L16.7649 16.3626L16.7549 25.9901L25.9999 21.1626L25.9899 11.8076Z"
        fill="#1760EE"
      />
    </svg>
  );
}

export enum Path {
  Main = '/',
  ParcelSize = '/parcel-size',
  ParcelDescription = '/parcel-description',
  DeliveryAddress = '/delivery-address',
  AllTariffs = '/all-tariffs',
  DeliveryDetails = '/delivery-details',
  Authorization = '/authorization',
  PublicOffer = '/public-offer',
  ConfirmationCode = '/confirmation-code',
  SenderDetails = '/sender-details',
  ParcelRules = '/parcel-rules',
  RecipientDetails = '/recipient-details',
  PaymentError = '/payment-error',
  PaymentSuccess = '/payment-success',
  PrintLabel = '/print-label',
  OrderCreated = '/order-created',
  PlaceParcel = '/place-parcel',
  Feedback = '/feedback',
}

export enum FeedbackPath {
  Dislike = 'dislike',
  Done = 'done',
}

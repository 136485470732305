import { useTranslations } from '@omnipkg/ui-kit-web';

export function useReasons(): string[] {
  const { t } = useTranslations();

  return [
    t('uncomfortableReturnRegistration'),
    t('problemsWithPayment'),
    t('noCellOfRightSize'),
    t('hardFindParcelLocker'),
    t('other'),
  ];
}

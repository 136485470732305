import { useEffect } from 'react';
import { useTranslations } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useOrderDataContext } from '@src/contexts/OrderDataContext';
import {
  StyledWrapper,
  TotalLabel,
  TotalValue,
  TotalWrapper,
} from '@src/theme/common';
import { Path } from '@src/types/routes';

import Instructions from './components/Instructions/Instructions';
import { useProcessingUid } from './hooks/useProcessingUid';

import { OrderNumber, Section } from './styled';

export default function OrderCreated(): JSX.Element {
  const {
    deviceInfo,
    countryMeasurements: { currency },
  } = useDeviceInfoContext();
  const { orderData, isRecipientPays } = useOrderDataContext();
  const { setIsPayBtn, setButtonState } = useFlowManagerContext();
  const { t } = useTranslations();

  const { isLoading } = useProcessingUid();

  const isHasPrinter = deviceInfo?.device_with_printer;
  const totalCost = orderData?.cost?.services_payment
    ? orderData.cost.services_payment[0].sum
    : 0;

  useEffect(() => {
    if (isRecipientPays) {
      setIsPayBtn(false);

      setButtonState({
        text: isHasPrinter ? t('print') : t('openCell'),
        isActive: true,
        isLoading,
        link: { to: isHasPrinter ? Path.PrintLabel : Path.PlaceParcel },
      });
    } else {
      setIsPayBtn(true);
    }
  }, [
    isLoading,
    isHasPrinter,
    isRecipientPays,
    setIsPayBtn,
    setButtonState,
    t,
  ]);

  return (
    <FlowManager title={t('deliveryHasBeenRegistered')}>
      <StyledWrapper>
        <Section>
          <OrderNumber>№{orderData?.order_number}</OrderNumber>
          {totalCost > 0 && (
            <TotalWrapper>
              <TotalLabel>{t('totalCost')}:</TotalLabel>
              <TotalValue>{`${currency} ${totalCost}`}</TotalValue>
            </TotalWrapper>
          )}
        </Section>
      </StyledWrapper>
      <Instructions />
    </FlowManager>
  );
}

import { useTranslations } from '@omnipkg/ui-kit-web';

import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useOrderDataContext } from '@src/contexts/OrderDataContext';
import { StyledWrapper } from '@src/theme/common';

import { InstructionsItem, InstructionsList, Section, Title } from './styled';

export default function Instructions() {
  const { deviceInfo } = useDeviceInfoContext();
  const { isRecipientPays } = useOrderDataContext();
  const { t } = useTranslations();

  const isHasPrinter = deviceInfo?.device_with_printer;

  function getFirstInstruction() {
    if (isRecipientPays) {
      if (isHasPrinter) {
        return t('afterPrintingOpenCell');
      }

      return t('afterPaymentOpenCell');
    } else {
      if (isHasPrinter) {
        return t('afterPrintingOpenCell');
      }

      return t('afterOpenCell');
    }
  }

  return (
    <StyledWrapper>
      <Section>
        <Title>{t('shippingInstructions')}</Title>
        <InstructionsList>
          <InstructionsItem>{getFirstInstruction()}</InstructionsItem>
          <InstructionsItem>{t('putParcelIntoOpeningCell')}</InstructionsItem>
          <InstructionsItem>{t('closeCellInstructions')}</InstructionsItem>
        </InstructionsList>
      </Section>
    </StyledWrapper>
  );
}

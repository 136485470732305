import EconomyDelivery from '@src/components/SVGIcon/icons/EconomyDelivery';
import ExpressDelivery from '@src/components/SVGIcon/icons/ExpressDelivery';
import StandardDelivery from '@src/components/SVGIcon/icons/StandardDelivery';
import { DeliveryType } from '@src/contexts/TariffsContext/types';

export default function useGegDeliveryIcon(type: keyof DeliveryType) {
  switch (type) {
    case 30:
      return <EconomyDelivery />;

    case 20:
      return <ExpressDelivery />;

    default:
      return <StandardDelivery />;
  }
}

import { useTranslations } from '@omnipkg/ui-kit-web';

export function useOffers(): string[] {
  const { t } = useTranslations();

  return [
    t('gadgets'),
    t('clothes'),
    t('printed'),
    t('cosmetics'),
    t('sportGear'),
    t('automotiveParts'),
    t('shoes'),
  ];
}

import styled from 'styled-components';

export const Form = styled.form`
  position: relative;
  width: 100%;

  display: flex;
  justify-content: center;
`;

export const SingleInput = styled.input`
  font-size: ${({ theme }): string => theme.typography.fontSize(32)};
  color: ${({ theme }): string => theme.palette.text.primary};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  text-align: center;

  width: 35px;
  height: 45px;

  display: flex;
  align-items: center;

  background: transparent;
  border: none;

  outline: none;

  &::placeholder {
    color: ${({ theme }): string => theme.palette.grey[30]};
  }
`;

import { CountryCode } from '@omnipkg/ui-kit-web';

interface INNType {
  code: number;
  country: string;
}

export function getINN(countryCode: CountryCode, innList: INNType[]) {
  const inn = innList.find((item) => item.country === countryCode);

  return inn?.code;
}

import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';

interface RecordUserDataArgs {
  phone: string;
  firstName: string;
  lastName: string;
  token: string;
}

interface UserCompanyRequest {
  contact: {
    name: string;
    phone: string;
  };
}

interface UsersCurrentRequest {
  first_name: string;
  last_name: string;
}

export function useRecordUserData() {
  const { request } = useApiContext();

  return useCallback(
    async ({
      phone,
      firstName,
      lastName,
      token,
    }: RecordUserDataArgs): Promise<void> => {
      Promise.allSettled([
        request<never, UserCompanyRequest>({
          url: 'user/company/',
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            contact: {
              name: `${firstName} ${lastName}`,
              phone,
            },
          },
        }),
        request<never, UsersCurrentRequest>({
          url: 'users/current/',
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: {
            first_name: firstName,
            last_name: lastName,
          },
        }),
      ]).catch((error) => console.error(error));
    },
    [request],
  );
}

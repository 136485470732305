import { Loader, useTranslations } from '@omnipkg/ui-kit-web';

import { VerticalCenteringWrapper } from '@src/theme/common';

import { LoaderWrapper, Text, Title } from './styled';

export default function Loading() {
  const { t } = useTranslations();

  return (
    <VerticalCenteringWrapper>
      <LoaderWrapper>
        <Loader />
        <Title>{t('labelIsPrinting')}</Title>
        <Text>{t('itMayTakeFewSeconds')}</Text>
      </LoaderWrapper>
    </VerticalCenteringWrapper>
  );
}

import styled from 'styled-components';

import { H1Bold } from '@src/theme/common';

export const OrderNumber = styled.h2`
  ${H1Bold}
  text-align: center;

  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const Section = styled.div`
  width: 100%;
`;

import { SVGPropsExtended } from '../types';

export default function Money({ title, titleId, ...props }: SVGPropsExtended) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="112"
      height="112"
      viewBox="0 0 112 112"
      fill="none"
      aria-labelledby={titleId}
      {...props}
    >
      {!!title && <title id={titleId}>{title}</title>}

      <rect
        x="16.5"
        y="16.5"
        width="79"
        height="79"
        rx="39.5"
        stroke="#F00000"
      />
      <path
        d="M63.7914 47.0225H49.2087C49.2087 47.0225 39 55.997 39 66.4701C39 72.4518 43.39 75.4447 46.2913 75.4447H66.7087C69.6221 75.4447 74 72.4518 74 66.4701C74 55.997 63.7914 47.0225 63.7914 47.0225ZM57.9567 67.813V70.2082H55.0434V67.8212C52.8963 67.4118 51.5473 66.0525 51.4516 64.0791H53.9778C54.1015 65.1149 55.1192 65.7823 56.5918 65.7823C57.9487 65.7823 58.9105 65.1067 58.9105 64.1446C58.9105 63.3257 58.2879 62.859 56.7554 62.5069L55.1312 62.1507C52.8563 61.6635 51.7389 60.4475 51.7389 58.4945C51.7389 56.5416 53.008 55.1332 55.0274 54.6705V52.259H57.9407V54.6746C59.9122 55.125 61.2252 56.5047 61.2891 58.3594H58.8507C58.7269 57.3481 57.809 56.6726 56.5479 56.6726C55.2868 56.6726 54.3769 57.2949 54.3769 58.2693C54.3769 59.0595 54.9715 59.4976 56.4362 59.8333L57.9407 60.1609C60.451 60.7013 61.5325 61.7986 61.5325 63.8007C61.5325 65.942 60.1916 67.3749 57.9567 67.813Z"
        fill="#2D2F30"
      />
      <path
        d="M63.7909 38.0437H59.7601C59.3485 37.5753 58.8456 37.2008 58.2839 36.9445C57.7222 36.6881 57.1143 36.5557 56.4996 36.5557C55.8848 36.5557 55.2769 36.6881 54.7153 36.9445C54.1536 37.2008 53.6507 37.5753 53.239 38.0437H49.2082C48.9708 38.0448 48.7371 38.1052 48.5276 38.2198C48.318 38.3344 48.1388 38.4996 48.0056 38.7013C47.8723 38.9029 47.789 39.1348 47.7628 39.377C47.7366 39.6191 47.7684 39.8641 47.8553 40.0908L49.2082 47.3008H63.7909L65.1438 40.0908C65.2308 39.8641 65.2625 39.6191 65.2364 39.377C65.2102 39.1348 65.1268 38.9029 64.9936 38.7013C64.8603 38.4996 64.6812 38.3344 64.4716 38.2198C64.262 38.1052 64.0284 38.0448 63.7909 38.0437Z"
        fill="#2D2F30"
      />
      <rect
        x="87.4775"
        y="22.6479"
        width="1"
        height="93.3333"
        rx="0.5"
        transform="rotate(45 87.4775 22.6479)"
        fill="#F00000"
      />
    </svg>
  );
}

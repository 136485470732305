import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { StorageKey } from '@src/constants/storageKey';
import { useSessionStorage } from '@src/utils/useStorage';

import { ContextProps } from '../types';
import { Tariff, TariffsContextValue } from './types';

const TariffsContext = createContext<TariffsContextValue | null>(null);

export function TariffsContextProvider({
  children,
}: ContextProps): JSX.Element {
  const [selectedTariff, setTariff] = useState<Tariff>();

  useEffect(() => {
    const tariff = useSessionStorage.get<Tariff>(StorageKey.Tariff);

    if (tariff) {
      setTariff(tariff);
    }
  }, []);

  const setSelectedTariff = useCallback((tariff: Tariff) => {
    setTariff(tariff);
    useSessionStorage.save(StorageKey.Tariff, tariff);
  }, []);

  const clearSelectedTariff = useCallback(() => {
    setTariff(undefined);
    useSessionStorage.remove(StorageKey.Tariff);
  }, []);

  const value: TariffsContextValue = useMemo(
    () => ({
      selectedTariff,
      setSelectedTariff,
      clearSelectedTariff,
    }),
    [selectedTariff, setSelectedTariff, clearSelectedTariff],
  );

  return (
    <TariffsContext.Provider value={value}>{children}</TariffsContext.Provider>
  );
}

export function useTariffsContext(): TariffsContextValue {
  const value = useContext(TariffsContext);

  if (value) return value;

  throw new Error(
    'Please, use useTariffsContext hook inside TariffsContextProvider',
  );
}

import { SVGIcon, useTranslations } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import Message from '@src/components/Message';

import Reasons from './components/Reasons';

const icon = <SVGIcon name="dislike-face" fillVariant="error" size={100} />;

export default function Dislike(): JSX.Element {
  const { t } = useTranslations();

  return (
    <FlowManager>
      <Message icon={icon} title={t('sorryYouAreNotSatisfied')}>
        {t('specifyReason')}
        <Reasons />
      </Message>
    </FlowManager>
  );
}

import { WithUiContexts } from '@omnipkg/ui-kit-web';

import { DeliveryAddressContextProvider } from '@src/contexts/DeliveryAddressContext';
import { DeviceInfoContextProvider } from '@src/contexts/DeviceInfoContext';
import { FlowManagerContextProvider } from '@src/contexts/FlowManagerContext';
import { InputFieldsContextProvider } from '@src/contexts/InputFieldsContext';
import { OrderDataContextProvider } from '@src/contexts/OrderDataContext';
import { ParcelContextProvider } from '@src/contexts/ParcelContext';
import { TariffsContextProvider } from '@src/contexts/TariffsContext';
import { GlobalStyle } from '@src/theme/global';

import { useRoutes } from './hooks/useRoutes';

import { RootContainer } from './styled';

export default function App(): JSX.Element {
  const routes = useRoutes();

  return (
    <WithUiContexts
      translationSettings={{
        projectLangs: ['en', 'ar'],
        defaultLang: 'en',
      }}
    >
      <GlobalStyle />

      <InputFieldsContextProvider>
        <DeviceInfoContextProvider>
          <ParcelContextProvider>
            <FlowManagerContextProvider>
              <DeliveryAddressContextProvider>
                <TariffsContextProvider>
                  <OrderDataContextProvider>
                    <RootContainer>{routes}</RootContainer>
                  </OrderDataContextProvider>
                </TariffsContextProvider>
              </DeliveryAddressContextProvider>
            </FlowManagerContextProvider>
          </ParcelContextProvider>
        </DeviceInfoContextProvider>
      </InputFieldsContextProvider>
    </WithUiContexts>
  );
}

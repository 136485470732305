import { SVGPropsExtended } from '../types';

export default function ExpressDelivery({
  title,
  titleId,
  ...props
}: SVGPropsExtended) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="28"
      viewBox="0 0 16 28"
      fill="none"
      aria-labelledby={titleId}
      {...props}
    >
      {!!title && <title id={titleId}>{title}</title>}
      <path
        d="M5.2844 27.9335C5.64563 28.0919 6.06562 27.9597 6.27661 27.6179L15.8765 11.9773C16.032 11.7242 16.0413 11.4045 15.9007 11.1434C15.7601 10.8814 15.4913 10.7188 15.1999 10.7188H8.79295L11.1757 1.0222C11.2719 0.630478 11.0773 0.2259 10.7156 0.0664856C10.3562 -0.0921082 9.93358 0.0408372 9.72339 0.382087L0.123503 16.0227C-0.0319622 16.2759 -0.0413489 16.5955 0.0992895 16.8566C0.239928 17.1186 0.508671 17.2812 0.800081 17.2812H7.20705L4.82425 26.9778C4.72814 27.3696 4.92265 27.7741 5.2844 27.9335Z"
        fill="#1760EE"
      />
    </svg>
  );
}

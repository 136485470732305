import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { ExtendedCellsInfo } from '@src/contexts/DeviceInfoContext/types';
import { useParcelContext } from '@src/contexts/ParcelContext';

import { Cell, UltraTheme } from './styled';

interface UltraProps {
  cells: ExtendedCellsInfo[];
}

export default function Ultra({ cells }: UltraProps) {
  const {
    selectCell,
    countryMeasurements: { unitOfLength },
    setSelectCell,
  } = useDeviceInfoContext();
  const { setParcelInfo } = useParcelContext();

  function onclick(cell: ExtendedCellsInfo) {
    if (cell?.has_empty) {
      setSelectCell(cell);
      setParcelInfo('width', cell.params.width);
      setParcelInfo('height', cell.params.height);
      setParcelInfo('length', cell.params.depth);
    }
  }

  return (
    <UltraTheme>
      {cells.map((cell: ExtendedCellsInfo) => {
        const { type, has_empty: isEmpty, params } = cell;

        const isActivated = selectCell?.type === type;
        const height = Math.trunc(params.height / 10);
        const width = Math.trunc(params.width / 10);
        const depth = Math.trunc(params.depth / 10);

        return (
          <Cell
            key={type}
            $isActivated={isActivated}
            $isDisabled={!isEmpty}
            onClick={() => onclick(cell)}
          >{`${height}x${width}x${depth} ${unitOfLength}`}</Cell>
        );
      })}
    </UltraTheme>
  );
}

import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useErrorHandlerContext } from '@omnipkg/ui-kit-web';

import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useOrderDataContext } from '@src/contexts/OrderDataContext';
import { FeedbackPath, Path } from '@src/types/routes';

import { FeedbackType } from '../types';
import { useSendFeedback } from '../utils/sendFeedback';

interface HookReturn {
  isPending: boolean;
  onConfirm: (reason?: string) => void;
}

export function useOnConfirm(): HookReturn {
  const [isPending, setIsPending] = useState(false);
  const navigate = useNavigate();

  const { deviceInfo } = useDeviceInfoContext();
  const { orderData, processingUid } = useOrderDataContext();
  const { handleError } = useErrorHandlerContext();

  const sendFeedback = useSendFeedback();

  const onConfirm = useCallback(
    (reason?: string) => {
      if (!deviceInfo || !processingUid) return;

      setIsPending(true);

      sendFeedback({
        deviceUid: deviceInfo.uid,
        orderUid: processingUid,
        recipientName: `${orderData.sender.name} ${orderData.sender.secondName}`,
        type: reason ? FeedbackType.Negative : FeedbackType.Positive,
        comment: reason || '',
      })
        .then(() => {
          navigate(`${Path.Feedback}/${FeedbackPath.Done}`);
        })
        .catch((error) => {
          handleError(error.message);
        })
        .finally(() => setIsPending(false));
    },
    [
      deviceInfo,
      processingUid,
      orderData.sender.name,
      orderData.sender.secondName,
      navigate,
      handleError,
      sendFeedback,
    ],
  );

  return {
    isPending,
    onConfirm,
  };
}

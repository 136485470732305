import styled from 'styled-components';

export const ReasonsWrapper = styled.div`
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: ${({ theme }) => theme.spacing(3)};

  margin-top: ${({ theme }) => theme.spacing(3)};
`;

export enum StorageKey {
  DeviceUid = 'deviceUid',
  DeviceInfo = 'deviceInfo',
  SelectedCell = 'selectedCell',
  ParcelInfo = 'parcelInfo',
  DeliveryAddress = 'deliveryAddress',
  Tariff = 'tariff',
  OrderData = 'orderData',
  ProcessingUid = 'processingUid',
  LabelLink = 'labelLink',
  OpenedCell = 'openedCell',
  IsRecipientPays = 'isRecipientPays',
  InputFields = 'inputFields',
}

export const convertedIdx = {
  0: 6,
  1: 4,
  2: 2,
  3: 5,
  4: 3,
  5: 1,
  6: 7,
  7: 0,
};

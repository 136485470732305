import styled, { css } from 'styled-components';

import { Txt1 } from '@src/theme/common';

import { InputWrapperProps } from './types';

function getSpacingLeft({ $spacingLeft }: InputWrapperProps) {
  return css`
    padding-left: ${({ theme }) => theme.spacing($spacingLeft)};
  `;
}

export const InputWrapper = styled.div<InputWrapperProps>`
  width: 100%;

  position: relative;

  input {
    ${getSpacingLeft}

    &::placeholder {
      ${Txt1}
      color: ${({ theme }) => theme.palette.grey[30]};
    }
  }
`;

export const Label = styled.label`
  ${Txt1}

  position: absolute;
  top: 50%;
  left: ${({ theme }) => theme.spacing(2)};

  transform: translateY(-50%);

  z-index: 1;
`;

import styled, { css } from 'styled-components';

import { H2Bold, Txt1Bold, Txt3 } from '@src/theme/common';

import { TariffItemProps } from './types';

function applyTariffColor({ $isSelected }: TariffItemProps) {
  return $isSelected
    ? css`
        background-color: rgba(243, 247, 254, 1);
        border-color: #1760ee;
      `
    : css``;
}

export const TariffWrapper = styled.div<TariffItemProps>`
  position: relative;

  min-height: 80px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing()};

  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  padding: ${({ theme }) => `0 ${theme.spacing(2)}`};

  transition: all 0.3s ease-in-out;

  overflow: hidden;

  ${applyTariffColor};
`;

export const TariffDescriptionContainer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing()};
`;

export const ImageWrapper = styled.div`
  width: 32px;
  height: 32px;
`;

export const TariffInfo = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

export const TariffName = styled.div`
  ${Txt1Bold}
`;

export const TariffDeliveryTime = styled.div`
  ${Txt3}
`;

export const TariffPriceContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

export const TariffPrice = styled.div`
  ${H2Bold}
  color: rgba(23, 96, 238, 1);
`;

import styled from 'styled-components';

import { Breakpoints } from './../../constants/breakpoints';

export const RootContainer = styled.div`
  height: 100dvh;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2.5)};

  padding: ${({ theme }) => `${theme.spacing(2.5)} ${theme.spacing(2)}`};

  @media (min-width: ${Breakpoints.Mobile}) {
    padding: ${({ theme }) => theme.spacing(2.5)};
  }
`;

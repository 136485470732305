import { forwardRef, ReactNode, useImperativeHandle } from 'react';

import { CODE_LENGTH } from './constants';
import { useInputActions } from './hooks/useInputActions';
import { AccessCodeProps, AccessCodeRef } from './types';

import { Form, SingleInput } from './styled';

const AccessCode = forwardRef<AccessCodeRef, AccessCodeProps>(
  ({ onChangeCode }, ref): JSX.Element => {
    const {
      value,
      onChangeHandler,
      onFocusHandler,
      onBlurHandler,
      onkeydownHandler,
    } = useInputActions(onChangeCode);

    function getInputs(): ReactNode {
      const inputs: ReactNode[] = [];

      for (let i = 0; i < CODE_LENGTH; i++) {
        inputs.push(
          <SingleInput
            key={`input/${i}`}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            placeholder="-"
            value={value[i] || ''}
            id={String(i)}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            onInput={onChangeHandler}
            onKeyDown={onkeydownHandler}
          />,
        );
      }

      return inputs;
    }

    useImperativeHandle(ref, () => {
      return {
        getAccessCode: () => value.join(''),
      };
    });

    return <Form>{getInputs()}</Form>;
  },
);

AccessCode.displayName = 'AccessCode';

export default AccessCode;

import { SVGPropsExtended } from '../types';

export default function Error({ title, titleId, ...props }: SVGPropsExtended) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="101"
      viewBox="0 0 100 101"
      fill="none"
      aria-labelledby={titleId}
      {...props}
    >
      {!!title && <title id={titleId}>{title}</title>}

      <circle cx="50" cy="50.5" r="50" fill="#CC0000" />
      <path
        d="M31.6133 68.8906C39.8162 60.6877 68.3719 32.1102 68.3719 32.1102"
        stroke="white"
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.6015 32.1211C39.8045 40.324 68.382 68.8797 68.382 68.8797"
        stroke="white"
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';

interface GetConfirmationCodeArgs {
  phone: string;
  deviceUid: string;
}

export function useGetConfirmationCode() {
  const { request } = useApiContext();

  return useCallback(
    async ({ phone, deviceUid }: GetConfirmationCodeArgs): Promise<void> => {
      await request({
        url: `c2c/user/confirmation/code/send?phone=${phone}&device_uid=${deviceUid}`,
      });
    },
    [request],
  );
}

import styled from 'styled-components';

export const Text = styled.span`
  a {
    color: inherit;
  }
`;

export const PhoneNumberWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

import { useCallback, useState } from 'react';
import {
  ErrorType,
  useAuthContext,
  useErrorHandlerContext,
} from '@omnipkg/ui-kit-web';

import { useOrderDataContext } from '@src/contexts/OrderDataContext';

import { useGetLabelUid } from '../utils/getLabelUid';

interface HookReturn {
  isError: boolean;
  isPending: boolean;
  getLabelLink: () => void;
}

export function useLabelLink(): HookReturn {
  const [isError, setIsError] = useState(false);
  const [isPending, setIsPending] = useState(false);

  const { token } = useAuthContext();
  const { orderData } = useOrderDataContext();
  const { handleError } = useErrorHandlerContext();
  const { recordLabelLink } = useOrderDataContext();

  const orderUid = orderData?.order_uid;

  const getLabelUid = useGetLabelUid();

  const getLabelLink = useCallback(() => {
    if (!orderUid) return;

    setIsError(false);
    setIsPending(true);

    getLabelUid({
      orderUid,
      token,
    })
      .then((data) => {
        recordLabelLink(data.print_results[0].label_link);
      })
      .catch((error) => {
        if (error.message === ErrorType.permissionDenied) {
          setIsError(true);
        }

        handleError(error.message);
      })
      .finally(() => setIsPending(false));
  }, [token, orderUid, getLabelUid, recordLabelLink, handleError]);

  return { isError, isPending, getLabelLink };
}

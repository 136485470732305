import { ChangeEvent, useCallback, useEffect } from 'react';
import { Input, useTranslations } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import InputOfDecimal from '@src/components/InputOfDecimal/InputOfDecimal';
import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useParcelContext } from '@src/contexts/ParcelContext';
import { Section, Title } from '@src/theme/common';
import { Path } from '@src/types/routes';

import { InsuranceList } from './constants';
import { useOffers } from './hooks/useOffers';

import { ContentItem, List } from './styled';

export default function ParcelDescription() {
  const { isEditInsurance, setButtonState } = useFlowManagerContext();
  const { parcelInfo, setParcelInfo } = useParcelContext();
  const { t } = useTranslations();
  const {
    countryMeasurements: { currency },
  } = useDeviceInfoContext();

  const isActive = !!parcelInfo.description && Number(parcelInfo.insurance) > 0;

  const contentsList = useOffers();

  const onChangeInsurance = useCallback(
    (value: string) => {
      setParcelInfo('insurance', value);
    },
    [setParcelInfo],
  );

  function onChangeDescription(e: ChangeEvent<HTMLInputElement>) {
    setParcelInfo('description', e.target.value);
  }

  useEffect(() => {
    setButtonState({
      text: t('next'),
      isActive,
      link: { to: isEditInsurance ? Path.AllTariffs : Path.DeliveryAddress },
    });
  }, [isActive, isEditInsurance, setButtonState, t]);

  return (
    <FlowManager title={t('parcelContentsTitle')}>
      <Input
        name="description"
        type="text"
        placeholder={t('contentOfParcel')}
        value={parcelInfo?.description}
        onChange={onChangeDescription}
      />

      <List>
        {contentsList.map((item) => (
          <ContentItem
            key={item}
            onClick={() => setParcelInfo('description', item)}
          >
            {item}
          </ContentItem>
        ))}
      </List>

      <Section>
        <Title>{t('specifySumInsured')}</Title>
        <InputOfDecimal
          label={currency}
          value={parcelInfo.insurance}
          onChange={onChangeInsurance}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={isEditInsurance}
        />
      </Section>

      <List>
        {InsuranceList.map((item) => (
          <ContentItem
            key={item}
            onClick={() => setParcelInfo('insurance', item)}
          >
            {item}
          </ContentItem>
        ))}
      </List>
    </FlowManager>
  );
}

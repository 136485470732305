import { useCallback, useEffect } from 'react';
import { useTranslations } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import InputOfDecimal from '@src/components/InputOfDecimal';
import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useParcelContext } from '@src/contexts/ParcelContext';
import { Section, Title } from '@src/theme/common';
import { Path } from '@src/types/routes';

import InputOfDimension from './components/InputOfDimension';
import { parseWeightLimit } from './utils/parseWeightLimit';

export default function ParcelSize() {
  const { isEditCell, setButtonState } = useFlowManagerContext();
  const { deviceInfo } = useDeviceInfoContext();
  const { parcelInfo, setParcelInfo } = useParcelContext();
  const { t } = useTranslations();
  const {
    countryMeasurements: { unitOfWeight },
  } = useDeviceInfoContext();

  const weightLimit = parseWeightLimit(deviceInfo?.weight_limit);
  const isActive =
    parcelInfo.height > 0 &&
    parcelInfo.width > 0 &&
    parcelInfo.length > 0 &&
    Number(parcelInfo.weight) > 0;

  const onChangeWeight = useCallback(
    (value: string) => {
      setParcelInfo('weight', value);
    },
    [setParcelInfo],
  );

  useEffect(() => {
    setButtonState({
      text: t('next'),
      isActive: isActive,
      link: { to: isEditCell ? Path.AllTariffs : Path.ParcelDescription },
    });
  }, [isActive, isEditCell, setButtonState, t]);

  return (
    <FlowManager title={t('parcelWeightTitle')}>
      <InputOfDecimal
        label={unitOfWeight}
        value={parcelInfo.weight}
        minLimit={weightLimit.max}
        maxLimit={weightLimit.max}
        onChange={onChangeWeight}
        placeholder="- -"
        spacingLeft={3.5}
      />

      <Section>
        <Title>{t('parcelDimensionsTitle')}</Title>
        <InputOfDimension type="width" label={t('width')} />
        <InputOfDimension type="height" label={t('height')} />
        <InputOfDimension type="length" label={t('length')} />
      </Section>
    </FlowManager>
  );
}

import { useEffect } from 'react';
import { useTranslations } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { Path } from '@src/types/routes';

import { Text } from './styled';

export default function PublicOffer(): JSX.Element {
  const { setButtonState } = useFlowManagerContext();
  const { t } = useTranslations();

  useEffect(() => {
    setButtonState({
      text: t('back'),
      isActive: true,
      link: { to: Path.Authorization },
    });
  }, [setButtonState, t]);

  return (
    <FlowManager title={t('publicOffer')}>
      <Text></Text>
    </FlowManager>
  );
}

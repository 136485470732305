import { useEffect } from 'react';
import { useTranslations } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import Drugs from '@src/components/SVGIcon/icons/Drugs';
import ExplosiveItems from '@src/components/SVGIcon/icons/ExplosiveItems';
import Money from '@src/components/SVGIcon/icons/Money';
import PerishableProducts from '@src/components/SVGIcon/icons/PerishableProducts';
import Poison from '@src/components/SVGIcon/icons/Poison';
import Weapons from '@src/components/SVGIcon/icons/Weapons';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { Path } from '@src/types/routes';

import { Prohibition, Prohibitions, ProhibitionText } from './styled';

export default function ParcelRules(): JSX.Element {
  const { setButtonState } = useFlowManagerContext();
  const { t } = useTranslations();

  const prohibitions = [
    {
      icon: <Poison />,
      text: t('poison'),
    },
    {
      icon: <ExplosiveItems />,
      text: t('explosiveItems'),
    },
    {
      icon: <Weapons />,
      text: t('weapons'),
    },
    {
      icon: <PerishableProducts />,
      text: t('perishableProducts'),
    },
    {
      icon: <Money />,
      text: t('money'),
    },
    {
      icon: <Drugs />,
      text: t('drugs'),
    },
  ];

  useEffect(() => {
    setButtonState({
      text: t('confirm'),
      isActive: true,
      link: { to: Path.RecipientDetails },
    });
  }, [setButtonState, t]);

  return (
    <FlowManager title={t('prohibitedDangerousGoods')}>
      <Prohibitions>
        {prohibitions.map(({ icon, text }) => (
          <Prohibition key={text}>
            {icon}
            <ProhibitionText>{text}</ProhibitionText>
          </Prohibition>
        ))}
      </Prohibitions>
    </FlowManager>
  );
}

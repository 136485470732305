import { SVGPropsExtended } from '../types';

export default function EconomyDelivery({
  title,
  titleId,
  ...props
}: SVGPropsExtended) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      aria-labelledby={titleId}
      {...props}
    >
      {!!title && <title id={titleId}>{title}</title>}
      <path
        d="M1.31707 11.3321C1.33855 11.354 1.37261 11.3836 1.4181 11.4198C1.82706 10.4714 2.44486 9.28148 3.37871 8.03872C4.2309 6.90473 5.10708 6.04462 5.84352 5.41676C5.29443 6.14565 4.64141 7.16118 4.10006 8.46549C3.4782 9.96392 3.22233 11.3164 3.11259 12.3049C4.42638 12.7514 6.23758 12.8607 7.82717 11.2336C10.573 8.42291 7.40892 4.80186 12.2325 1.56094C12.2325 1.56094 6.03223 -0.169748 2.07596 3.87962C-1.8807 7.92937 0.971393 10.9845 1.31707 11.3321Z"
        fill="#1760EE"
      />
      <path
        d="M21.0634 14.4989C21.0634 14.4989 25.3612 9.64727 23.5584 4.24754C21.7559 -1.152 17.786 0.0702457 17.3252 0.227985C17.2964 0.237856 17.2544 0.255663 17.2027 0.280436C17.8983 0.791591 19.1941 1.89616 20.0321 3.77026C20.5249 4.87211 20.6989 5.88165 20.7612 6.60028C20.2416 5.76475 19.5051 4.74573 18.4867 3.71278C17.4777 2.6895 16.4827 1.94745 15.665 1.42197C14.6839 2.41447 13.7775 4.0089 14.5024 6.17855C15.7536 9.92637 20.3614 8.67393 21.0634 14.4989Z"
        fill="#1760EE"
      />
      <path
        d="M18.8678 18.1904C18.4833 16.8406 17.5423 15.2669 15.3186 14.8655C11.4777 14.172 10.3394 18.8707 4.96875 16.6881C4.96875 16.6881 7.08943 22.8456 12.6231 23.845C18.1568 24.8445 19.0138 20.7274 19.0991 20.2418C19.1044 20.211 19.1094 20.1659 19.1129 20.1077C18.1686 20.41 16.7868 20.7336 15.1016 20.7232C13.7044 20.7144 12.5339 20.4787 11.6749 20.2354C12.7036 20.1907 14.034 20.0345 15.5288 19.5948C16.896 19.1926 18.0162 18.6665 18.8678 18.1904Z"
        fill="#1760EE"
      />
    </svg>
  );
}

import { SVGPropsExtended } from '../types';

export default function PerishableProducts({
  title,
  titleId,
  ...props
}: SVGPropsExtended) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="112"
      height="112"
      viewBox="0 0 112 112"
      fill="none"
      aria-labelledby={titleId}
      {...props}
    >
      {!!title && <title id={titleId}>{title}</title>}

      <rect
        x="16.5"
        y="16.5"
        width="79"
        height="79"
        rx="39.5"
        stroke="#F00000"
      />
      <path
        d="M82.2852 49.2606C80.9601 48.4017 71.2738 44.475 60.9 46.6891C59.847 46.9138 58.8323 47.1897 57.8443 47.4971C57.65 47.5575 57.4421 47.5591 57.2476 47.4996L48.9187 44.9524C48.1895 44.7294 47.5028 45.3699 47.7446 46.093C48.2098 47.4845 48.8412 48.7765 49.4452 49.8435C49.7334 50.3528 49.5527 51.0066 49.0348 51.279C44.5728 53.626 40.7448 56.046 37.1128 56.9006C36.9304 56.9435 36.7399 56.9294 36.5638 56.8654L28.6281 53.9837C27.8899 53.7156 27.1633 54.3617 27.4169 55.1051C28.3793 57.9259 30.0102 60.3227 30.6799 61.2391C30.8657 61.4933 30.9346 61.8163 30.8688 62.1241C30.6316 63.2341 30.1213 66.088 30.3944 69.0558C30.4663 69.838 31.3934 70.1311 31.9579 69.585L38.0256 63.7146C38.1603 63.5843 38.3284 63.4936 38.5124 63.4583C42.1768 62.7557 46.6593 63.4025 51.6906 63.7229C52.2746 63.7601 52.7065 64.2831 52.6513 64.8657C52.5356 66.0863 52.4867 67.5235 52.6301 68.9836C52.7046 69.7424 53.593 70.0468 54.1676 69.5455L60.7305 63.8199C60.8836 63.6863 61.0739 63.6028 61.2757 63.5786C62.3036 63.4554 63.3428 63.2935 64.3959 63.0687C74.7697 60.8546 82.0089 53.3155 82.8678 51.9905C83.7267 50.6655 83.6049 50.116 82.2852 49.2606ZM75.4787 52.1404C74.7247 52.3013 73.9833 51.8208 73.8224 51.0668C73.6614 50.3128 74.142 49.5714 74.896 49.4105C75.65 49.2496 76.3914 49.7301 76.5523 50.4841C76.7131 51.2376 76.2327 51.9795 75.4787 52.1404Z"
        fill="#2D2F30"
      />
      <rect
        x="85.8115"
        y="22.6479"
        width="1"
        height="93.3333"
        rx="0.5"
        transform="rotate(45 85.8115 22.6479)"
        fill="#F00000"
      />
    </svg>
  );
}

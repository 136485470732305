import styled from 'styled-components';

import { H2, ImageStyled } from '@src/theme/common';

export const Image = styled(ImageStyled)`
  margin: 0 auto;
`;

export const Title = styled.h2`
  ${H2}
  text-align: center;

  margin-top: ${({ theme }) => theme.spacing(2)};
`;

import { InputFields } from './types';

export const DEFAULT_FIELDS_VALUES: InputFields = {
  senderName: '',
  senderSurname: '',
  senderFullNumber: '',
  senderNumber: '',
  recipientName: '',
  recipientSurname: '',
  recipientFullNumber: '',
  recipientNumber: '',
};

import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';

interface PrintLabelArgs {
  token?: string;
  deviceId: string;
  labelLink: string;
  project: string;
}

interface PrintLabelRequest {
  label: string;
  device_id: string;
  project: string;
  size: 'A4' | 'A5' | 'A6';
}

const baseURL = process.env.API_PRINT;

export function usePrintLabel() {
  const { request } = useApiContext();

  return useCallback(
    async (value: PrintLabelArgs): Promise<void> => {
      await request<never, PrintLabelRequest>({
        baseURL,
        url: '/device/print',
        method: 'POST',
        headers: {
          // TODO: add Bearer token after fix
          Authorization:
            'Token 9877f9631b2e30570cc1c5aa8c740b0c912efe99a8b6c45c21cf4bd394adc7e9',
        },
        data: {
          label: value.labelLink,
          device_id: value.deviceId,
          project: value.project,
          size: 'A6',
        },
      });
    },
    [request],
  );
}

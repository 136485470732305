import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';

import { FeedbackType } from '../types';

interface SendFeedbackArgs {
  deviceUid: string;
  orderUid: string;
  type: FeedbackType;
  comment?: string;
  recipientName: string;
}

interface SendFeedbackRequest {
  device_uid: string;
  order_uid: string;
  type: FeedbackType;
  comment?: string;
  recipient_name: string;
}

export function useSendFeedback() {
  const { request } = useApiContext();

  return useCallback(
    async (data: SendFeedbackArgs) => {
      await request<never, SendFeedbackRequest>({
        url: '/feedback/',
        method: 'POST',
        data: {
          device_uid: data.deviceUid,
          order_uid: data.orderUid,
          type: data.type,
          recipient_name: data.recipientName,
          comment: data.comment,
        },
      });
    },
    [request],
  );
}

import { useTranslations } from '@omnipkg/ui-kit-web';

import DeliveryTime from '@src/components/DeliveryTime';
import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useTariffsContext } from '@src/contexts/TariffsContext';
import {
  StyledWrapper,
  TotalLabel,
  TotalValue,
  TotalWrapper,
} from '@src/theme/common';

import {
  DeliveryTimeLabel,
  DeliveryTimeValue,
  DeliveryTimeWrapper,
  TariffDetailsStyled,
} from './styled';

export default function Total() {
  const { selectedTariff } = useTariffsContext();
  const {
    countryMeasurements: { currency },
  } = useDeviceInfoContext();
  const { t } = useTranslations();

  const minDays = selectedTariff?.days_min;
  const maxDays = selectedTariff?.days_max;
  const tariffCost = selectedTariff?.delivery_cost
    ? selectedTariff?.delivery_cost
    : 0;
  const additionalServicesCost = selectedTariff?.additional_services_cost
    ? selectedTariff?.additional_services_cost
    : 0;
  const total = tariffCost + additionalServicesCost;

  const additionalServicesName = (key: string) => {
    return `${t(key)}: `;
  };

  return (
    <StyledWrapper>
      <TariffDetailsStyled>
        <TotalWrapper>
          <TotalLabel>{t('deliveryCost')}:</TotalLabel>
          <TotalValue>{`${currency} ${total}`}</TotalValue>
        </TotalWrapper>

        <DeliveryTimeWrapper>
          <DeliveryTimeLabel>{t('tariff')}: </DeliveryTimeLabel>
          <DeliveryTimeValue>{`${currency} ${tariffCost}`}</DeliveryTimeValue>
        </DeliveryTimeWrapper>

        {selectedTariff?.additional_services_calculator?.map(
          ({ type, cost }) => (
            <DeliveryTimeWrapper key={type}>
              <DeliveryTimeLabel>
                {additionalServicesName(type)}
              </DeliveryTimeLabel>
              <DeliveryTimeValue>{`${currency} ${cost}`}</DeliveryTimeValue>
            </DeliveryTimeWrapper>
          ),
        )}

        <DeliveryTimeWrapper>
          <DeliveryTimeLabel>{t('estimatedDeliveryTime')}: </DeliveryTimeLabel>
          <DeliveryTimeValue>
            <DeliveryTime minDays={minDays} maxDays={maxDays} />
          </DeliveryTimeValue>
        </DeliveryTimeWrapper>
      </TariffDetailsStyled>
    </StyledWrapper>
  );
}

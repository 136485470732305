import styled, { css } from 'styled-components';

import { Breakpoints } from '@src/constants/breakpoints';

import { CellProps } from './types';

function applyActivatedStyles({ $isActivated }: CellProps) {
  return (
    $isActivated &&
    css`
      color: #1760ee;
      background-color: #f3f7fe;
      border-color: #1760ee;
    `
  );
}

function applyDisabledStyles({ $isDisabled }: CellProps) {
  return (
    $isDisabled &&
    css`
      pointer-events: none;
      color: #ccc;
      background-color: #fff;
      border-color: #e5e5e5;
    `
  );
}

export const UltraTheme = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 100px 76px 56px 44px 76px;
  justify-content: center;
  grid-gap: ${({ theme }) => theme.spacing(2)};

  @media (min-width: ${Breakpoints.Mobile}) {
    grid-template-columns: 194px 120px;
  }
`;

export const Cell = styled.div<CellProps>`
  min-height: 44px;
  grid-column: 1 / 3;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #000000;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.typography.fontWeight[700]};
  line-height: 140%;

  background-color: #f2f2f2;
  border: 1px solid #cccccc;
  border-radius: 8px;

  transition: all 0.3s ease;

  &:nth-child(2),
  &:nth-child(4),
  &:nth-child(6) {
    grid-column: 1 / 2;
  }

  &:nth-child(3),
  &:nth-child(5),
  &:nth-child(7) {
    grid-column: 2 / 3;
  }

  ${applyActivatedStyles}
  ${applyDisabledStyles}


  @media (min-width: ${Breakpoints.Mobile}) {
    font-size: 16px;
  }
`;

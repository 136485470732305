import { CountryCode } from '@omnipkg/ui-kit-web';

import { INN_LIST } from '@src/constants/inn';
import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { getINN } from '@src/utils/getINN';

export default function useINN() {
  const { deviceInfo } = useDeviceInfoContext();

  const countryCode = deviceInfo?.location.country_settings
    .country_code as CountryCode;
  const inn = getINN(countryCode, INN_LIST);

  return process.env.NODE_ENV === 'production' && inn ? inn : 7707419906;
}

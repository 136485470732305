import { useEffect, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { DEVICE_UID } from '@src/constants/routing';
import { StorageKey } from '@src/constants/storageKey';
import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useSessionStorage } from '@src/utils/useStorage';

export function useSaveQuery() {
  const savedDeviceUid = useRef<string | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const { setDeviceUid } = useDeviceInfoContext();

  useEffect(() => {
    const deviceUid = searchParams.get(DEVICE_UID);

    if (deviceUid) {
      savedDeviceUid.current = deviceUid;

      setDeviceUid(deviceUid);
      useSessionStorage.save(StorageKey.DeviceUid, deviceUid);
    }
  }, [searchParams, setDeviceUid]);

  useEffect(() => {
    const deviceUid = savedDeviceUid.current;

    if (deviceUid) {
      setSearchParams({ [DEVICE_UID]: deviceUid }, { replace: true });
    }
  }, [pathname, setSearchParams]);

  return savedDeviceUid.current;
}

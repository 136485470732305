import { useId } from 'react';
import { Input } from '@omnipkg/ui-kit-web';

import { InputOfDecimalProps } from './types';

import { InputWrapper, Label } from './styled';

export default function InputOfDecimal({
  label,
  value,
  onChange,
  placeholder = '- - - -',
  spacingLeft = 2.5,
  autoFocus,
  maxLimit,
}: InputOfDecimalProps) {
  const inputID = useId();

  const handleWeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = event.target.value.replace(/[^0-9.,]/g, '');

    if (newValue.startsWith('0') && !newValue.match(/[.,]/)) {
      newValue = newValue.substring(1);
    }

    if (newValue.split(/\.|,/).length <= 2) {
      const parts = newValue.split(/\.|,/);

      if (parts.length === 2 && parts[1].length > 2) {
        return;
      }

      const formattedValue =
        parts.length === 2 && parts[0] === '' ? `0${parts[1]}.` : newValue;

      const numericValue = parseFloat(formattedValue);

      if (!isNaN(numericValue) && maxLimit && numericValue > maxLimit) {
        return onChange(maxLimit.toLocaleString());
      }

      onChange(formattedValue.replace(/,/, '.'));
    }
  };

  return (
    <InputWrapper $spacingLeft={spacingLeft}>
      <Label htmlFor={inputID}>{label}</Label>
      <Input
        id={inputID}
        type="text"
        value={value}
        onChange={handleWeightChange}
        inputMode="decimal"
        pattern="[0-9.,]"
        placeholder={placeholder}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
      />
    </InputWrapper>
  );
}

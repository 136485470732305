import { Outlet } from 'react-router-dom';
import { ApiContextProvider } from '@omnipkg/ui-kit-web';

import Header from '@src/components/Header';

import { useSaveQuery } from './hooks/useSaveQuery';

const baseURL = process.env.API;

export default function MainLayout() {
  useSaveQuery();

  return (
    <ApiContextProvider baseURL={baseURL as string}>
      <Header />
      <Outlet />
    </ApiContextProvider>
  );
}

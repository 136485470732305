import styled from 'styled-components';

import { Txt3 } from '@src/theme/common';

export const Prohibitions = styled.div`
  max-width: 280px;
  margin: 0 auto;

  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(3)};
`;

export const Prohibition = styled.div`
  width: 112px;
  text-align: center;
`;

export const ProhibitionText = styled.p`
  ${Txt3};
`;

export enum InputName {
  SenderName = 'senderName',
  SenderSurname = 'senderSurname',
  SenderFullNumber = 'senderFullNumber',
  SenderNumber = 'senderNumber',
  RecipientName = 'recipientName',
  RecipientSurname = 'recipientSurname',
  RecipientFullNumber = 'recipientFullNumber',
  RecipientNumber = 'recipientNumber',
}

export type InputFields = {
  [K in InputName]: string;
};

export interface InputFieldsContextValue {
  inputFields: InputFields;
  recordInputFields: (data: InputFields) => void;
  clearInputFields: () => void;
}

import { SVGPropsExtended } from '../types';

export default function Drugs({ title, titleId, ...props }: SVGPropsExtended) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="112"
      height="112"
      viewBox="0 0 112 112"
      fill="none"
      aria-labelledby={titleId}
      {...props}
    >
      {!!title && <title id={titleId}>{title}</title>}

      <rect
        x="16.5"
        y="16.5"
        width="79"
        height="79"
        rx="39.5"
        stroke="#F00000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.201 65.9758C59.9705 66.5251 61.7721 69.1928 61.2195 71.9299C60.6637 74.6702 57.9645 76.4475 55.195 75.9014L37.1181 72.334C34.3486 71.7878 32.547 69.1202 33.0997 66.3831C33.6523 63.6428 36.3515 61.8654 39.1242 62.4116L57.201 65.9758ZM55.5943 73.9156C57.2585 74.244 58.878 73.1769 59.2103 71.5353C59.5425 69.8905 58.4596 68.2899 56.7985 67.9616L46.7555 65.9821L45.5544 71.9362L55.5943 73.9156ZM69.8315 52.0788L67.589 48.6787C66.685 47.3086 67.0747 45.4712 68.4611 44.5778C69.8474 43.6844 71.7097 44.0695 72.6137 45.4396L78.5137 54.3802C79.4177 55.7503 79.0248 57.5877 77.6384 58.4811C76.2521 59.3746 74.393 58.9894 73.4858 57.6193L71.7736 55.0211C71.7481 55.4694 71.6203 55.9208 71.3743 56.3375L65.9727 65.578C65.1454 66.9955 63.3086 67.4817 61.8743 66.6609C60.4401 65.8432 59.9513 64.028 60.7787 62.6105L66.1803 53.37C66.9246 52.0977 68.4834 51.5768 69.8315 52.0788ZM43.6474 60.2175C41.9767 61.6539 39.482 61.897 37.5238 60.6563C35.144 59.1504 34.4509 56.0187 35.9746 53.6667L45.9218 38.3301C47.4455 35.9782 50.6143 35.2931 52.9941 36.799C55.3738 38.3048 56.067 41.4366 54.5433 43.7885L47.7937 54.2003L57.8527 52.3471C59.7277 51.9999 61.5357 53.2248 61.8871 55.0811C62.2353 56.9342 60.9959 58.7211 59.1208 59.0652L46.8801 61.3224C45.6375 61.5497 44.4268 61.092 43.6474 60.2175ZM70.3362 55.744C70.8345 54.8948 70.5374 53.8056 69.6781 53.3132C68.8189 52.8238 67.7168 53.1143 67.2185 53.9635L64.219 59.0968L67.3335 60.8773L70.3362 55.744ZM58.8653 57.7235C59.9929 57.5151 60.734 56.4449 60.5263 55.3305C60.3155 54.2192 59.2326 53.4836 58.105 53.692L51.3042 54.9422L52.0677 58.9768L58.8653 57.7235ZM74.492 56.9721C75.0351 57.7929 76.1499 58.0265 76.9836 57.4898C77.8141 56.9532 78.0505 55.8482 77.5075 55.0274L74.2301 50.0615L71.2146 52.003L74.492 56.9721ZM52.8184 42.6962C53.7319 41.285 53.3167 39.4066 51.8888 38.5037C50.4609 37.5977 48.5603 38.0112 47.6435 39.4224L42.1205 47.9431L47.2921 51.2169L52.8184 42.6962Z"
        fill="#2D2F30"
      />
      <rect
        x="87.4775"
        y="22.6479"
        width="1"
        height="93.3333"
        rx="0.5"
        transform="rotate(45 87.4775 22.6479)"
        fill="#F00000"
      />
    </svg>
  );
}

import { useTranslations } from '@omnipkg/ui-kit-web';

import { Title } from '@src/theme/common';

import { Text, Wrapper } from './styled';

export default function ErrorOpenCell() {
  const { t } = useTranslations();

  return (
    <Wrapper>
      <Title>{t('thereAreOpenCells')}</Title>
      <Text>{t('pleaseCoseCells')}</Text>
    </Wrapper>
  );
}

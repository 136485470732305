import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';

import { DeviceInfoResponse } from '@src/contexts/DeviceInfoContext/types';

interface GetDeviceInfoArgs {
  deviceUid: string;
}

export default function useGetDeviceInfoRequest() {
  const { request } = useApiContext();

  return useCallback(
    async ({ deviceUid }: GetDeviceInfoArgs) => {
      const { data } = await request<DeviceInfoResponse>({
        url: `public/device/${deviceUid}/`,
        method: 'GET',
      });

      return data;
    },
    [request],
  );
}

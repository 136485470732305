import styled from 'styled-components';

import { StyledWrapper, Title as TitleBase } from '@src/theme/common';

export const DeliveryDetailsStyled = styled(StyledWrapper)`
  display: block;
`;

export const Title = styled(TitleBase)`
  text-align: left;

  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const CellDetailsWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing()};
  }
`;

export const EditInfo = styled.div`
  overflow: hidden;
`;

export const Bold = styled.p`
  font-weight: ${({ theme }) => theme.typography.fontWeight[700]};
`;

export const Text = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  padding-right: ${({ theme }) => theme.spacing()};
`;

export const EditButton = styled.button`
  width: 24px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  border: none;
  outline: none;

  padding: 0;
`;

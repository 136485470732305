import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';

interface GetLabelUidArgs {
  token: string;
  orderUid: string;
}

interface GetLabelUidResponse {
  print_results: {
    order_uid: string;
    print_status: boolean;
    label_link: string;
    message: string;
  }[];
}

interface GetLabelUidRequest {
  orders: string[];
  size: 'A4' | 'A5' | 'A6';
}

const baseURL = process.env.API_ECOM;

export function useGetLabelUid() {
  const { request } = useApiContext();

  return useCallback(
    async ({
      token,
      orderUid,
    }: GetLabelUidArgs): Promise<GetLabelUidResponse> => {
      const { data } = await request<GetLabelUidResponse, GetLabelUidRequest>({
        baseURL,
        url: '/type-1/print/label',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          orders: [orderUid],
          size: 'A6',
        },
      });

      return data;
    },
    [request],
  );
}

import { useCallback } from 'react';
import { useApiContext } from '@omnipkg/ui-kit-web';

interface GetConfirmationCodeArgs {
  phone: string;
  deviceUid: string;
  code: string;
}

interface CheckCodeResponse {
  first_name: string | null;
  last_name: string | null;
  token_data: string;
}

export interface CheckConfirmationCode {
  device_uid: string;
  phone: string;
  code: string;
}

export function useCheckCode() {
  const { request } = useApiContext();

  return useCallback(
    async ({
      phone,
      deviceUid,
      code,
    }: GetConfirmationCodeArgs): Promise<CheckCodeResponse> => {
      const { data } = await request<CheckCodeResponse, CheckConfirmationCode>({
        url: 'c2c/user/authentication/',
        method: 'POST',
        data: {
          phone,
          device_uid: deviceUid,
          code,
        },
      });

      return data;
    },
    [request],
  );
}

import { styled } from 'styled-components';

export const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(1.5)} ${theme.spacing(3.75)}px`};
`;

export const Logo = styled.div`
  max-width: 91px;

  svg {
    width: 100%;
    height: auto;
  }
`;

export const Button = styled.button`
  background: none;
  border: none;
  transform: rotate(90deg);
`;

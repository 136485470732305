import { useEffect, useMemo } from 'react';
import { Loader, ParcelLocker, useTranslations } from '@omnipkg/ui-kit-web';

import FlowManager from '@src/components/FlowManager';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { VerticalCenteringWrapper } from '@src/theme/common';
import { Path } from '@src/types/routes';

import ErrorOpenCell from './components/ErrorOpenCell';
import useDeviceConfigs from './hooks/useDeviceConfigs';
import { usePlaceParcelHandler } from './hooks/usePlaceParcelHandler';

import { ParcelLockerWrapper } from './styled';

export default function PlaceParcel() {
  const { t } = useTranslations();
  const { setButtonState } = useFlowManagerContext();

  const { isLoading, deviceConfig } = useDeviceConfigs();
  const { isPending, openedCell, isThereAreOpenCells, placeParcelHandler } =
    usePlaceParcelHandler();

  const title = isThereAreOpenCells ? t('error') : t('placeParcelIntoOpenCell');
  const isHasDeviceConfig = !isLoading && deviceConfig;
  const ignoreOpenCellsHandler = useMemo(
    () =>
      isThereAreOpenCells
        ? () => placeParcelHandler({ ignoreOpenCells: true })
        : undefined,
    [isThereAreOpenCells, placeParcelHandler],
  );

  useEffect(() => {
    placeParcelHandler();
  }, [placeParcelHandler]);

  useEffect(() => {
    setButtonState({
      text: isThereAreOpenCells ? t('iCantClose') : t('next'),
      isActive: true,
      isLoading: isLoading || isPending,
      link: isThereAreOpenCells ? undefined : { to: Path.Feedback },
      buttonAction: ignoreOpenCellsHandler,
      secondButtonText: isThereAreOpenCells ? t('iClose') : undefined,
      secondButtonAction: ignoreOpenCellsHandler,
    });
  }, [
    isLoading,
    isPending,
    isHasDeviceConfig,
    isThereAreOpenCells,
    setButtonState,
    placeParcelHandler,
    ignoreOpenCellsHandler,
    t,
  ]);

  return isHasDeviceConfig ? (
    <FlowManager title={title}>
      <ParcelLockerWrapper>
        <ParcelLocker
          stacks={deviceConfig.stacks}
          openCells={openedCell}
          error={isThereAreOpenCells}
        />
      </ParcelLockerWrapper>

      <>{isThereAreOpenCells && <ErrorOpenCell />}</>
    </FlowManager>
  ) : (
    <VerticalCenteringWrapper>
      <Loader />
    </VerticalCenteringWrapper>
  );
}

export const INN_LIST = [
  {
    code: 68955312,
    country: 'LV',
  },
  {
    code: 6895531911,
    country: 'TH',
  },
  {
    code: 68955319,
    country: 'US',
  },
  {
    code: 68955315,
    country: 'SA',
  },
  {
    code: 68955316,
    country: 'BR',
  },
  {
    code: 68955314,
    country: 'AE',
  },

  {
    code: 89894798391198,
    country: 'GE',
  },
  {
    code: 68955318,
    country: 'IL',
  },
  {
    code: 68955317,
    country: 'IT',
  },
];

import { SVGPropsExtended } from '../types';

export default function ExplosiveItems({
  title,
  titleId,
  ...props
}: SVGPropsExtended) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      aria-labelledby={titleId}
      {...props}
    >
      {!!title && <title id={titleId}>{title}</title>}

      <rect x="0.5" y="0.5" width="79" height="79" rx="39.5" stroke="#F00000" />
      <path
        d="M61.9201 23.6571L60.2518 20.7321C60.0903 20.4662 59.7674 20.4131 59.4983 20.5194L58.6372 20.998L57.238 18.5518C56.9689 18.02 56.2692 17.8604 55.7849 18.1263C55.2467 18.3922 55.0852 19.0836 55.3543 19.5622L56.7536 22.0085L51.7485 24.827L42.5994 29.9323L24.9471 39.8238C24.9471 39.7707 24.9471 39.6643 24.8933 39.6111L23.6555 37.4839C23.494 37.218 23.1711 37.1648 22.902 37.2712L19.7267 39.0793C19.4577 39.2389 19.4038 39.5579 19.5115 39.8238L20.7493 41.9511C20.8031 42.0042 20.8569 42.0574 20.9107 42.1106L19.4577 42.9083C18.166 43.6528 17.6278 45.1419 18.2737 46.2587L21.6104 52.1085C22.2562 53.2253 23.8169 53.5443 25.1086 52.853L25.6467 52.5339L28.2838 62C28.2838 62 32.9122 61.5214 33.6118 59.0219C34.3114 56.5224 33.1813 56.1502 33.1813 56.1502C33.1813 56.1502 32.7507 55.5652 32.2125 54.7675L37.4867 51.8426C38.0249 51.5767 38.1864 50.8853 37.9173 50.4067L35.8722 46.8436L42.8685 42.9083C45.99 47.1095 51.1565 49.4495 56.5921 48.8645C56.3231 46.7905 56.2154 45.7269 55.9463 43.6528C50.9951 44.2378 48.1427 42.6424 46.1514 38.2284L44.9674 36.1544L53.5245 31.3682L54.0089 29.0814L54.0627 28.7624L61.8125 24.4548C61.974 24.242 62.0816 23.923 61.9201 23.6571ZM31.0824 52.9594C30.7595 52.3212 30.4365 51.683 30.3289 51.1512L34.4729 48.8113L35.4416 50.5131L31.0824 52.9594Z"
        fill="#2D2F30"
      />
      <rect
        x="71.4775"
        y="6.64795"
        width="1"
        height="93.3333"
        rx="0.5"
        transform="rotate(45 71.4775 6.64795)"
        fill="#F00000"
      />
    </svg>
  );
}

import styled from 'styled-components';

import { H4 } from '@src/theme/common';

export const RateButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ theme }): string => theme.spacing(2)};

  background-color: ${({ theme }): string => theme.palette.primary[5]};
  border-radius: 6px;

  padding: ${({ theme }): string => `${theme.spacing(4)} ${theme.spacing(2)}`};
  margin-top: ${({ theme }): string => theme.spacing(4)};
  margin-bottom: ${({ theme }): string => theme.spacing(4)};
`;

export const RateTitle = styled.h4`
  ${H4}
  width: 100%;
  text-align: center;
`;

export const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
`;

import { SVGPropsExtended } from '../types';

export default function Weapons({
  title,
  titleId,
  ...props
}: SVGPropsExtended) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="112"
      height="112"
      viewBox="0 0 112 112"
      fill="none"
      aria-labelledby={titleId}
      {...props}
    >
      {!!title && <title id={titleId}>{title}</title>}

      <rect
        x="16.5"
        y="16.5"
        width="79"
        height="79"
        rx="39.5"
        stroke="#F00000"
      />
      <path
        d="M58.8366 45.8816C58.8595 45.5222 58.9747 45.2161 59.163 44.89C59.8891 43.6322 61.4693 43.2402 62.6694 43.9331L68.485 47.2907C69.7312 48.0102 70.1088 49.5947 69.4095 50.8058C69.2212 51.1319 68.9676 51.3581 68.7139 51.5843C72.8653 56.6519 73.6186 63.9811 70.1491 69.9904C65.6577 77.7698 55.7228 80.481 48.0148 76.0308C40.3068 71.5806 37.6605 61.6677 42.1788 53.8417C45.6483 47.8325 52.4184 44.8469 58.8366 45.8816Z"
        fill="#2D2F30"
      />
      <path
        d="M54.2593 34.7298C53.826 34.4796 53.6794 33.9222 53.9319 33.4849C54.1844 33.0475 54.7404 32.8958 55.1737 33.146C55.6071 33.3962 55.7537 33.9535 55.5012 34.3909C55.2487 34.8283 54.6927 34.98 54.2593 34.7298Z"
        fill="#2D2F30"
      />
      <path
        d="M55.7385 32.1677C55.4581 32.0058 55.3633 31.6452 55.5267 31.3622C55.6901 31.0792 56.0498 30.981 56.3302 31.1429C56.6106 31.3048 56.7055 31.6654 56.5421 31.9484C56.3787 32.2314 56.0189 32.3296 55.7385 32.1677Z"
        fill="#2D2F30"
      />
      <path
        d="M50.8168 40.6924C50.3834 40.4422 50.2368 39.8849 50.4893 39.4475C50.7419 39.0101 51.2979 38.8584 51.7312 39.1086C52.1646 39.3588 52.3112 39.9162 52.0586 40.3535C51.8061 40.7909 51.2501 40.9426 50.8168 40.6924Z"
        fill="#2D2F30"
      />
      <path
        d="M49.6334 42.7421C49.353 42.5802 49.2582 42.2195 49.4216 41.9366C49.5849 41.6536 49.9447 41.5554 50.2251 41.7173C50.5055 41.8792 50.6004 42.2398 50.437 42.5228C50.2736 42.8058 49.9138 42.904 49.6334 42.7421Z"
        fill="#2D2F30"
      />
      <path
        d="M51.6713 34.4157C51.238 34.1655 51.0914 33.6081 51.3439 33.1708C51.5964 32.7334 52.1524 32.5817 52.5857 32.8319C53.0191 33.0821 53.1657 33.6394 52.9132 34.0768C52.6607 34.5142 52.1047 34.6659 51.6713 34.4157Z"
        fill="#2D2F30"
      />
      <path
        d="M51.1585 31.5731C50.8781 31.4113 50.7833 31.0506 50.9467 30.7676C51.1101 30.4846 51.4698 30.3864 51.7502 30.5483C52.0306 30.7102 52.1255 31.0709 51.9621 31.3539C51.7987 31.6369 51.439 31.735 51.1585 31.5731Z"
        fill="#2D2F30"
      />
      <path
        d="M53.3779 41.0531C52.9445 40.8029 52.7979 40.2456 53.0504 39.8082C53.303 39.3708 53.8589 39.2191 54.2923 39.4693C54.7256 39.7195 54.8722 40.2769 54.6197 40.7142C54.3672 41.1516 53.8112 41.3033 53.3779 41.0531Z"
        fill="#2D2F30"
      />
      <path
        d="M54.2134 43.3367C53.933 43.1748 53.8381 42.8142 54.0015 42.5312C54.1649 42.2482 54.5247 42.15 54.8051 42.3119C55.0855 42.4738 55.1803 42.8344 55.0169 43.1174C54.8536 43.4004 54.4938 43.4986 54.2134 43.3367Z"
        fill="#2D2F30"
      />
      <path
        d="M49.5841 36.0056C49.1507 35.7554 49.0041 35.198 49.2567 34.7607C49.5092 34.3233 50.0652 34.1716 50.4985 34.4218C50.9319 34.672 51.0785 35.2294 50.8259 35.6667C50.5734 36.1041 50.0174 36.2558 49.5841 36.0056Z"
        fill="#2D2F30"
      />
      <path
        d="M47.4838 34.4204C47.2034 34.2585 47.1085 33.8979 47.2719 33.6149C47.4353 33.3319 47.7951 33.2337 48.0755 33.3956C48.3559 33.5575 48.4507 33.9181 48.2874 34.2011C48.124 34.4841 47.7642 34.5823 47.4838 34.4204Z"
        fill="#2D2F30"
      />
      <path
        d="M49.2239 38.6548C48.7905 38.4046 48.6439 37.8472 48.8964 37.4099C49.1489 36.9725 49.7049 36.8208 50.1383 37.071C50.5716 37.3212 50.7182 37.8786 50.4657 38.3159C50.2132 38.7533 49.6572 38.905 49.2239 38.6548Z"
        fill="#2D2F30"
      />
      <path
        d="M46.8285 39.073C46.5481 38.9112 46.4532 38.5505 46.6166 38.2675C46.78 37.9845 47.1398 37.8863 47.4202 38.0482C47.7006 38.2101 47.7954 38.5708 47.632 38.8538C47.4686 39.1368 47.1089 39.2349 46.8285 39.073Z"
        fill="#2D2F30"
      />
      <path
        d="M55.8253 36.814C55.392 36.5638 55.2454 36.0064 55.4979 35.5691C55.7504 35.1317 56.3064 34.98 56.7398 35.2302C57.1731 35.4804 57.3197 36.0377 57.0672 36.4751C56.8147 36.9125 56.2587 37.0642 55.8253 36.814Z"
        fill="#2D2F30"
      />
      <path
        d="M58.5435 35.8368C58.2631 35.6749 58.1682 35.3143 58.3316 35.0313C58.495 34.7483 58.8547 34.6501 59.1351 34.812C59.4155 34.9739 59.5104 35.3345 59.347 35.6175C59.1836 35.9005 58.8239 35.9987 58.5435 35.8368Z"
        fill="#2D2F30"
      />
      <path
        d="M53.5063 36.0341C53.5524 36.0607 53.5524 36.0607 53.5063 36.0341C56.2295 37.6063 58.876 39.6312 60.1669 37.3952C61.6192 34.8797 63.83 34.3548 66.5993 35.9537C68.9533 37.3128 69.6619 39.7095 68.371 41.9455L65.9773 46.0914L64.408 45.1853L66.8017 41.0394C67.5816 39.6885 67.1619 38.3903 65.6388 37.5109C63.4694 36.2585 62.47 36.9236 61.69 38.2746C59.6998 41.7217 55.5458 39.3234 52.5457 37.5913C52.1303 37.3514 51.9762 36.7656 52.2183 36.3464C52.5065 35.9537 53.0909 35.7943 53.5063 36.0341Z"
        fill="#2D2F30"
      />
      <rect
        x="87.1445"
        y="22.6479"
        width="1"
        height="93.3333"
        rx="0.5"
        transform="rotate(45 87.1445 22.6479)"
        fill="#F00000"
      />
    </svg>
  );
}

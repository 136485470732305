import styled from 'styled-components';

import { Txt3 } from '@src/theme/common';

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing()};

  margin-top: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

export const ContentItem = styled.button`
  ${Txt3}

  border: none;
  border-radius: 50px;
  background: #f3f7fe;

  padding: ${({ theme }) => `${theme.spacing()} ${theme.spacing(2)}`};
`;
